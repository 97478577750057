import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  deepCopy,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";

import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../constants";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getDepartments,
  getTurnaroundDetailsForPatch,
  patchTurnaroundProfile,
} from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import { getTurnaroundInfo } from "../../turnaroundUtils";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import { getDepartmentRoster } from "../../tasksOverviewApi";
import { useTasksOverviewDispatchContext } from "../../TasksOverviewContext";
import AssignLeadsSelector from "./AssignLeadsSelector";
export default function AssignLeadsModal(props) {
  const { onCloseHandler, onSaved, selectedTurnaroundUuid, timezone } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const tasksOverviewDispatch = useTasksOverviewDispatchContext();

  const { currentUser, turnaroundDetailsForPatch, users, departments } =
    mainContext;

  const [isProcessing, setIsProcessing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [assignmentsToEdit, setAssignmentsToEdit] = useState(null);
  const [selectedTurnaroundInfo, setSelectedTurnaroundInfo] = useState(null);
  const [selectedSlotId, setSelectedSlotId] = useState(null);

  useEffect(() => {
    // Load supporting data
    getDepartments(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // Load supporting data
    if (!isEmptyList(departments)) {
      // TODO: HACK
      const departmentName = "PAX Oficiales";
      let dept = getByFieldValue(departments, "name", departmentName);
      if (isNullOrUndefined(dept)) {
        dept = departments[0];
      }
      getDepartmentRoster(tasksOverviewDispatch, dept.uuid);
    }
  }, [tasksOverviewDispatch, departments]);

  // Load the turnaround details
  useEffect(() => {
    if (!isNullOrUndefined(selectedTurnaroundUuid)) {
      getTurnaroundDetailsForPatch(dispatch, { uuid: selectedTurnaroundUuid });
      setIsProcessing(true);

      dispatch({
        type: "setIsRefreshPaused",
        value: true,
      });
    }
  }, [dispatch, selectedTurnaroundUuid]);

  useEffect(() => {
    if (!isNullOrUndefined(turnaroundDetailsForPatch)) {
      // Find the leads operation within the profile
      const turnaroundOperations =
        turnaroundDetailsForPatch?.profile?.turnaroundOperations;

      // List of lead contexts
      const leadContexts = [];
      if (!isEmptyList(turnaroundOperations)) {
        for (let i = 0; i < turnaroundOperations.length; i++) {
          const operation = turnaroundOperations[i];
          if (operation.type.isLeadOperation && operation.enabled) {
            // Create a context for the lead operation
            const leadContext = {
              uuid: operation.uuid,
              operation: operation,
            };

            // TODO: We assume the lead operation has only one main requirement
            const leadOperationRequirement = !isEmptyList(
              operation?.turnaroundRequirements
            )
              ? operation.turnaroundRequirements[0]
              : null;

            // Create slots
            const slots = [];
            if (!isNullOrUndefined(leadOperationRequirement)) {
              for (let i = 0; i < leadOperationRequirement.quantity; i++) {
                // Pair each slot with crewAssignment if there are enough existing
                const crewAssignment =
                  i < leadOperationRequirement?.crewAssignments?.length
                    ? leadOperationRequirement?.crewAssignments[i]
                    : null;
                const assignedUser =
                  !isEmptyList(users) && !isNullOrUndefined(crewAssignment)
                    ? getByFieldValue(users, "uuid", crewAssignment.userUuid)
                    : null;

                slots.push({
                  slotId: `slot-${operation.uuid}-${i}`,
                  assignedUser: assignedUser,
                  assignments: leadOperationRequirement?.crewAssignments,
                  requirement: leadOperationRequirement,
                });
              }
            }
            leadContext.operationRequirement = leadOperationRequirement;
            leadContext.operationName =
              leadOperationRequirement?.certification?.name;
            leadContext.slots = slots;
            leadContexts.push(leadContext);
          }
        }
        const turnaroundInfo = getTurnaroundInfo(turnaroundDetailsForPatch);
        setSelectedTurnaroundInfo(turnaroundInfo);

        // Sort lead operations by name
        sortByField(leadContexts, "operationName");

        setAssignmentsToEdit({
          uuid: turnaroundDetailsForPatch?.profile?.uuid,
          leadContexts: leadContexts,
        });
      }
      setTimeout(() => {
        setIsProcessing(false);
      }, 250);
    }
  }, [turnaroundDetailsForPatch, users]);

  const handleClose = () => {
    setAssignmentsToEdit(null);
    setSelectedTurnaroundInfo(null);
    setHasChanges(false);
    setIsProcessing(false);
    setSelectedSlotId(null);

    // Un-pause
    dispatch({
      type: "setIsRefreshPaused",
      value: false,
    });
    if (!isNullOrUndefined(onCloseHandler)) {
      onCloseHandler();
    }
  };

  async function handleSave() {
    setIsProcessing(true);
    // Create the patch request
    let patchRequest = {
      uuid: assignmentsToEdit.uuid,
      requirements: [],
    };

    const leadContexts = assignmentsToEdit.leadContexts;
    for (let i = 0; i < leadContexts.length; i++) {
      const leadContext = leadContexts[i];
      const slots = leadContext.slots;
      const leadOperationRequirement = leadContext.operationRequirement;

      // check what assignments need to be removed/added
      const userUuidsToAdd = [];
      const userUuidsToRemove = [];
      const originalAssignments = [];
      const updatedAssignments = [];

      // Assignments edited by user
      if (!isEmptyList(slots)) {
        slots.forEach((slot) => {
          if (!isNullOrUndefined(slot.assignedUser)) {
            updatedAssignments.push(slot.assignedUser.uuid);
          }
        });
      }
      // Original assignments
      if (!isEmptyList(leadOperationRequirement?.crewAssignments)) {
        leadOperationRequirement?.crewAssignments.forEach((crewAssignment) => {
          originalAssignments.push(crewAssignment.userUuid);
        });
      }
      originalAssignments.forEach((userUuid) => {
        if (!updatedAssignments.includes(userUuid)) {
          // Assigned user was removed
          userUuidsToRemove.push(userUuid);
        }
      });
      updatedAssignments.forEach((userUuid) => {
        if (!originalAssignments.includes(userUuid)) {
          // Assigned user was added
          userUuidsToAdd.push(userUuid);
        }
      });
      let requirement = {
        uuid: leadOperationRequirement.uuid,
      };
      if (!isEmptyList(userUuidsToAdd)) {
        requirement.userUuidsToAdd = userUuidsToAdd;
      }
      if (!isEmptyList(userUuidsToRemove)) {
        requirement.userUuidsToRemove = userUuidsToRemove;
      }
      patchRequest.requirements.push(requirement);
    }

    const result = await patchTurnaroundProfile(dispatch, patchRequest);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", {
          name: selectedTurnaroundInfo?.combinedFlightName,
        }),
      });
      if (!isNullOrUndefined(onSaved)) {
        onSaved();
      }
    }

    setIsProcessing(false);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_SAVE_LEADS);
    handleClose();
  }

  const hasEditTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnProfile
  );

  const isReadonly = selectedTurnaroundInfo?.isCompleted;

  function updateSlots(operationUuid, slot, selectedUser) {
    const contextsToUpdate = deepCopy(assignmentsToEdit);
    const leadContextToUpdate = getByFieldValue(
      contextsToUpdate.leadContexts,
      "uuid",
      operationUuid
    );
    const slotToUpdate = getByFieldValue(
      leadContextToUpdate.slots,
      "slotId",
      slot.slotId
    );
    slotToUpdate.assignedUser = selectedUser;
    setAssignmentsToEdit(contextsToUpdate);
    setSelectedSlotId(null);
    setHasChanges(true);
  }
  const hasSourceProfile =
    !isNullOrUndefined(turnaroundDetailsForPatch?.profile) ||
    !isNullOrUndefined(turnaroundDetailsForPatch?.monitor);

  return (
    <div>
      <Modal
        open={!isNullOrUndefined(selectedTurnaroundUuid)}
        onClose={handleClose}
      >
        <div className="modal">
          <div>
            <div className="modal-header">
              <h2>
                {!isNullOrUndefined(selectedTurnaroundInfo) && (
                  <>{`${t("turnaround")}: ${
                    selectedTurnaroundInfo?.combinedFlightName
                  }`}</>
                )}
              </h2>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="turnaround-edit-modal-content">
                {isProcessing && (
                  <div>
                    <LoadingIndicator />
                  </div>
                )}

                {!isProcessing && !hasSourceProfile && (
                  <div>
                    <div className="empty-state">
                      {t("turnaround_details_not_available")}
                    </div>
                  </div>
                )}
                {!isProcessing &&
                  hasSourceProfile &&
                  !isEmptyList(assignmentsToEdit?.leadContexts) &&
                  assignmentsToEdit?.leadContexts.map((leadContexts) => (
                    <AssignLeadsSelector
                      key={leadContexts.uuid}
                      leadContext={leadContexts}
                      selectedSlotId={selectedSlotId}
                      timezone={timezone}
                      hasEditTurnProfile={hasEditTurnProfile}
                      updateSlots={updateSlots}
                      isReadonly={isReadonly}
                      onSelect={setSelectedSlotId}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  onClick={handleSave}
                  disabled={isProcessing || !hasChanges}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
