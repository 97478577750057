import "./styles.css";
import CompanyBG from "../../assets/ornament.png";

import { useMainContext, useMainDispatchContext } from "../../MainContext";
import PageToolbar from "../PageToolbar";
import { useEffect, useRef } from "react";
import { isEmptyList, isNullOrUndefined } from "../../utils";
import {
  getPositions,
  getTurnaroundsSummary,
  getUsers,
  getVehicles,
  getWeather,
} from "../../api";
import TurnaroundChartWidget from "./TurnaroundChartWidget";
import TurnaroundCardsWidget from "./TurnaroundCardsWidget";
import WeatherWidget from "./WeatherWidget";
import LoadingIndicator from "../LoadingIndicator";
import { DEFAULT_TIMEZONE } from "../../constants";
import { useTranslation } from "react-i18next";
import ResourcesWidget from "./ResourcesWidget";

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    currentUser,
    weather,
    vehicles,
    turnaroundsSummary: turnarounds,
    turnaroundsSummaryLoading,
    weatherLoading,
    users,
    positions,
  } = mainContext;
  const hasTurnarounds = !isEmptyList(turnarounds);
  const hasWeather = !isNullOrUndefined(weather);
  const chartBoxRef = useRef(null);

  useEffect(() => {
    getWeather(dispatch);
    getVehicles(dispatch);
    getTurnaroundsSummary(dispatch);
    getUsers(dispatch);
    getPositions(dispatch);
  }, [dispatch]);

  if (isNullOrUndefined(currentUser)) return null;
  const { airport, company } = currentUser;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  const welcomeMsg = t("welcome_back", { name: currentUser.firstName });

  // TODO: Replace this with something more useful
  const showTurnaroundsWidget = false;

  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <PageToolbar title={welcomeMsg} />
        <div className="dashboard-widgets">
          <div
            className="widget company-info"
            style={{
              backgroundImage: `url(${CompanyBG})`,
            }}
          >
            <span>{t("organization")}</span>
            <span>{t("airport")}</span>
            <div>
              <h3>{company.name}</h3>
            </div>
            <div>
              <h3 title={airportTimezone}>{airport.iata}</h3>
            </div>
          </div>
          <div className="widget weather-info">
            {weatherLoading && <LoadingIndicator />}
            {!weatherLoading && hasWeather && (
              <WeatherWidget weather={weather} />
            )}
          </div>
          <div className="widget vehicle-info">
            <div className="widget-header">
              <h2>{t("resources")}</h2>
            </div>
            <ResourcesWidget
              vehicles={vehicles}
              users={users}
              positions={positions}
              currentUser={currentUser}
            />
          </div>
          <div className="widget turnaround-info">
            <div className="widget-header">
              <h2>{t("peak_hours")}</h2>
            </div>
            <div className="chart" ref={chartBoxRef}>
              {turnaroundsSummaryLoading && <LoadingIndicator />}
              {!turnaroundsSummaryLoading && hasTurnarounds && (
                <TurnaroundChartWidget
                  turnarounds={turnarounds}
                  airportTimezone={airportTimezone}
                />
              )}
            </div>
          </div>
          {showTurnaroundsWidget && (
            <div className="widget turnaround-cards">
              <TurnaroundCardsWidget
                turnarounds={turnarounds}
                airportTimezone={airportTimezone}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
