import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../constants";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTasksOverviewContext } from "../../TasksOverviewContext";
import { isEmptyList, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import SelectCrew from "../SelectCrew";

function AssignLeadsSelector(props) {
  const {
    leadContext,
    selectedSlotId,
    timezone,
    hasEditTurnProfile,
    updateSlots,
    isReadonly,
    onSelect,
  } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const tasksOverviewContext = useTasksOverviewContext();
  const { departmentRoster } = tasksOverviewContext;

  const { users, turnaroundsSummary, positions } = mainContext;

  const shiftContext = {
    taskInfo: {
      expectedStartTime: leadContext?.operation?.expectedStartTime,
      expectedEndTime: leadContext?.operation?.expectedEndTime,
    },
    rosterInfo: departmentRoster,
    timezone: timezone,
  };
  const leadOperation = leadContext?.operation;
  const leadOperationRequirement = leadContext?.operationRequirement;
  const slots = leadContext?.slots;

  return (
    <div className="turnaround-edit-modal-row">
      <div>
        <label>{leadOperationRequirement?.certification?.name}</label>
      </div>
      <div className="turnaround-edit-slots">
        {isNullOrUndefined(leadOperation) && <div>{t("na")}</div>}
        {!isEmptyList(slots) &&
          slots.map((slot) => (
            <div key={slot.slotId}>
              <SelectCrew
                isActive={selectedSlotId === slot.slotId}
                isProfileEditable={hasEditTurnProfile}
                isEditable={!isReadonly}
                isSelected={false}
                onClick={() => {
                  onSelect(slot.slotId);
                }}
                onSelect={(selectedUser) => {
                  updateSlots(leadOperation.uuid, slot, selectedUser.user);
                  logAnalyticsEvent(
                    dispatch,
                    ANALYTICS_EVENTS.TURNAROUNDS_ADD_ASSIGNMENT
                  );
                }}
                onRemove={() => {
                  updateSlots(leadOperation.uuid, slot, null);
                  logAnalyticsEvent(
                    dispatch,
                    ANALYTICS_EVENTS.TURNAROUNDS_REMOVE_ASSIGNMENT
                  );
                }}
                onCancel={() => {
                  onSelect(null);
                }}
                requirement={slot.requirement}
                assignments={slot.assignments}
                assignedUser={slot.assignedUser}
                users={users}
                positions={positions}
                turnarounds={turnaroundsSummary}
                shiftContext={shiftContext}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default AssignLeadsSelector;
