import "./styles.css";
import { ReactComponent as Download } from "../../assets/download.svg";
import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import TitleBadge from "../TitleBadge";
import {
  formatLongDate,
  formatLongDatetime,
  getByFieldValue,
  getTimezoneFromUser,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import ButtonDatePicker from "../ButtonDatePicker";
import {
  ANALYTICS_EVENTS,
  MIN_DATE,
  POLLING_INTERVALS,
  SEARCH_QUERY_PLACEHOLDERS,
  TurnaroundsPageMode,
} from "../../constants";
import DataTable from "../DataTable";
import {
  getTaskInfoForTurnaround,
  getTurnaroundInfo,
  getTurnaroundInfoForTurnaroundSummary,
} from "../../turnaroundUtils";
import {
  getAirportDetailByUuid,
  getGseTypes,
  getTurnaroundDetails,
  getTurnaroundsSummaryExport,
  getTurnaroundsSummarySearch,
  getUsers,
  getVehicles,
} from "../../api";
import { TurnaroundsModuleColumns } from "../DataTable/TurnaroundsModuleColumns";
import { useNavigate, useParams } from "react-router-dom";
import TurnaroundsModuleTasks from "./TurnaroundsModuleTasks";
import TurnaroundsModuleConfig from "./TurnaroundsModuleConfig";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import { getUserInfo } from "../../userUtils";
import TurnaroundEditModal from "./TurnaroundEditModal";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import { SettingNames, SettingsManager } from "../../SettingsManager";
import ButtonSwitch from "../ButtonSwitch";
import AssignLeadsModal from "./AssignLeadsModal";
import ChatButton from "../DataTable/ChatButton";

function TurnaroundsModule(props) {
  const { subPath } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const navigate = useNavigate();
  const { turnaroundUuid } = useParams();

  const {
    currentUser,
    turnaroundsSummarySearch,
    turnaroundsSummarySearchLoading,
    turnaroundDetails,
    searchQuery,
    vehicles,
    gseTypes,
    users,
    localSettings,
    turnaroundForAssignment,
    turnaroundsSummaryExport,
    turnaroundsSummaryExportSaving,
    isRefreshPaused,
  } = mainContext;
  const turnaroundsCount = turnaroundsSummarySearch?.length;
  const airportTimezone = getTimezoneFromUser(currentUser);
  const mToday = moment().tz(airportTimezone).startOf("day");
  const mNow = moment().tz(airportTimezone);
  const turnaroundsPageMode = !isNullOrUndefined(localSettings)
    ? localSettings[SettingNames.TURNAROUNDS_PAGE_MODE.name]
    : SettingsManager.getSetting(SettingNames.TURNAROUNDS_PAGE_MODE.name);
  const isActiveMode = TurnaroundsPageMode.ACTIVE === turnaroundsPageMode;

  const [selectedDate, setSelectedDate] = useState(mToday);
  const [selectedTurnaroundInfo, setSelectedTurnaroundInfo] = useState(null);
  const [selectedTurnaroundUuidForEdit, setSelectedTurnaroundUuidForEdit] =
    useState(null); // For popup editing
  const [taskInfoList, setTaskInfoList] = useState(null);
  const [refreshSelectedContextRequested, setRefreshSelectedContextRequested] =
    useState(false);
  const [
    refreshTurnaroundsSummaryRequested,
    setRefreshTurnaroundsSummaryRequested,
  ] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const hasEditTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnProfile
  );
  const hasEditStandAssignment = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditStandAssignment
  );

  // Pemission to use the edit Turnaround modal must have all of these
  const hasTurnaroundEditModal = hasEditTurnProfile || hasEditStandAssignment;
  function handleRowClick(item) {
    setSelectedTurnaroundUuidForEdit(item.uuid);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_EDIT_MODAL);
  }

  let rowActions = {
    chatAction: (item) => {
      return (
        <div
          onClick={(e) => {
            navigate(`/turnarounds/${item.uuid}/chat`);
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_VIEW_CHAT);
            e.stopPropagation();
          }}
        >
          <ChatButton turnaroundUuid={item.uuid} />
        </div>
      );
    },
  };

  rowActions.viewAction = (item) => {
    if (item.isCompleted) {
      return (
        <button
          className="primary"
          onClick={(e) => {
            navigate(`/turnarounds/${item.uuid}`);
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_CONFIGURE);
            e.stopPropagation();
          }}
        >
          {t("view_details")}
        </button>
      );
    } else {
      return (
        <button
          className="alternate"
          onClick={(e) => {
            navigate(`/turnarounds/${item.uuid}`);
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_CONFIGURE);
            e.stopPropagation();
          }}
        >
          {t("configure")}
        </button>
      );
    }
  };

  // Load supporting data
  useEffect(() => {
    if (!isNullOrUndefined(currentUser)) {
      getAirportDetailByUuid(dispatch, { uuid: currentUser?.airport.uuid });
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    // Load supporting data
    getGseTypes(dispatch);
    getVehicles(dispatch);
    getUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // Load required data
    if (isActiveMode) {
      const mNow = moment().tz(airportTimezone);
      const start = moment(mNow).add(-6, "hours").toDate().toISOString();
      const end = moment(mNow).add(24, "hours").toDate().toISOString();
      getTurnaroundsSummarySearch(dispatch, start, end);
    } else {
      const start = moment(selectedDate).toDate().toISOString();
      const end = moment(selectedDate).endOf("day").toDate().toISOString();
      getTurnaroundsSummarySearch(dispatch, start, end);
    }
    getVehicles(dispatch);
  }, [dispatch, selectedDate, isActiveMode, airportTimezone]);

  function handleReloadTurnarounds() {
    if (isActiveMode) {
      const mNow = moment().tz(airportTimezone);
      const start = moment(mNow).add(-6, "hours").toDate().toISOString();
      const end = moment(mNow).add(24, "hours").toDate().toISOString();
      getTurnaroundsSummarySearch(dispatch, start, end);
    } else {
      const start = moment(selectedDate).toDate().toISOString();
      const end = moment(selectedDate).endOf("day").toDate().toISOString();
      getTurnaroundsSummarySearch(dispatch, start, end);
    }
  }

  useEffect(() => {
    // Load turnaround details
    if (!isNullOrUndefined(turnaroundUuid)) {
      getTurnaroundDetails(dispatch, { uuid: turnaroundUuid });
    } else {
      dispatch({
        type: "setTurnaroundDetails",
        value: null,
      });
    }
  }, [dispatch, turnaroundUuid]);

  // Refresh TurnarnoundsSummary (list)
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (turnaroundsSummarySearchLoading) return () => {};
    if (!refreshTurnaroundsSummaryRequested) return () => {};
    if (isActiveMode) {
      const mNow = moment().tz(airportTimezone);
      const start = moment(mNow).add(-6, "hours").toDate().toISOString();
      const end = moment(mNow).add(24, "hours").toDate().toISOString();
      getTurnaroundsSummarySearch(dispatch, start, end);
    }
    setRefreshTurnaroundsSummaryRequested(false);
  }, [
    dispatch,
    turnaroundsSummarySearchLoading,
    refreshTurnaroundsSummaryRequested,
    isRefreshPaused,
    isActiveMode,
    airportTimezone,
    selectedDate,
  ]);

  // Refresh turnaround details
  useEffect(() => {
    if (!refreshSelectedContextRequested) return () => {};
    if (
      isNullOrUndefined(selectedTurnaroundInfo) ||
      selectedTurnaroundInfo.isCompleted
    )
      return () => {};

    // Load turnaround details
    getTurnaroundDetails(dispatch, { uuid: selectedTurnaroundInfo.uuid });
    setRefreshSelectedContextRequested(false);

    // This fixes the pulsing animation syncronization issue
    const animations = document.getAnimations();
    if (!isEmptyList(animations)) {
      animations.forEach((i) => (i.currentTime = 0));
    }
  }, [dispatch, refreshSelectedContextRequested, selectedTurnaroundInfo]);

  // Refresh intervals
  useEffect(() => {
    const intervals = [];
    // Interval for refreshing the selected turnaround
    if (POLLING_INTERVALS.TURNAROUNDS > 0) {
      const interval = setInterval(() => {
        setRefreshSelectedContextRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS);
      intervals.push(interval);
    }
    // Interval for refreshing the list of turnarounds
    if (POLLING_INTERVALS.TURNAROUNDS_SUMMARY > 0) {
      const interval = setInterval(() => {
        setRefreshTurnaroundsSummaryRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS_SUMMARY);
      intervals.push(interval);
    }
    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_TURNAROUNDS,
      },
    });
  }, [dispatch]);

  const dataList = useMemo(() => {
    const resultsToList = [];
    if (!isEmptyList(turnaroundsSummarySearch)) {
      for (let i = 0; i < turnaroundsSummarySearch.length; i++) {
        const turnaround = turnaroundsSummarySearch[i];

        const turnaroundInfo =
          getTurnaroundInfoForTurnaroundSummary(turnaround);

        // Include the turnaround only if it matches the current page mode
        if (
          (isActiveMode && !turnaroundInfo.isCompleted) ||
          (!isActiveMode && turnaroundInfo.isCompleted)
        ) {
          // Lookup for each lead user
          const leadsUserInfo = [];
          if (!isEmptyList(turnaround?.leadsUuids)) {
            turnaround?.leadsUuids.forEach((i) => {
              const user = getByFieldValue(users, "uuid", i);
              const userInfo = !isNullOrUndefined(user)
                ? getUserInfo(user)
                : null;
              if (!isNullOrUndefined(userInfo)) {
                leadsUserInfo.push(userInfo);
              }
            });
          }
          sortByField(leadsUserInfo, "fullName");

          resultsToList.push({
            ...turnaroundInfo,
            timezone: airportTimezone,
            showPlusDays: isActiveMode,
            showDate: !isActiveMode,
            leads: leadsUserInfo,
          });
        }
      }
    }
    return resultsToList;
  }, [turnaroundsSummarySearch, airportTimezone, users, isActiveMode]);

  useEffect(() => {
    if (isNullOrUndefined(turnaroundUuid)) {
      setSelectedTurnaroundInfo(null);
    } else {
      if (isNullOrUndefined(turnaroundDetails) || isNullOrUndefined(vehicles))
        return () => {};
      const turnaroundInfoToRender = !isNullOrUndefined(turnaroundDetails)
        ? getTurnaroundInfo(turnaroundDetails)
        : null;
      const taskInfoForTurnaround = !isNullOrUndefined(turnaroundInfoToRender)
        ? getTaskInfoForTurnaround(turnaroundInfoToRender, vehicles)
        : null;

      setSelectedTurnaroundInfo(turnaroundInfoToRender);
      setTaskInfoList(taskInfoForTurnaround);
    }
  }, [turnaroundUuid, turnaroundDetails, vehicles]);

  useEffect(() => {
    if (!isNullOrUndefined(turnaroundsSummaryExport)) {
      if (!isNullOrUndefined(turnaroundsSummaryExport.downloadUrl)) {
        window.open(turnaroundsSummaryExport.downloadUrl, "_blank");
      }
      dispatch({
        type: "setTurnaroundsSummaryExport",
        value: null,
      });
      setIsProcessing(false);
      logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_EXPORT_CSV);
    }
  }, [dispatch, turnaroundsSummaryExport]);

  function handleExportClick() {
    if (!turnaroundsSummaryExportSaving && !isProcessing) {
      setIsProcessing(true);
      if (isActiveMode) {
        const mNow = moment().tz(airportTimezone);
        const start = moment(mNow).add(-6, "hours").toDate().toISOString();
        const end = moment(mNow).add(24, "hours").toDate().toISOString();
        getTurnaroundsSummaryExport(dispatch, start, end);
      } else {
        const start = moment(selectedDate).toDate().toISOString();
        const end = moment(selectedDate).endOf("day").toDate().toISOString();
        getTurnaroundsSummaryExport(dispatch, start, end);
      }
    }
  }

  const showDetails =
    isBlank(subPath) && !isNullOrUndefined(selectedTurnaroundInfo);
  const showTimeline =
    subPath === "timeline" && !isNullOrUndefined(selectedTurnaroundInfo);
  const showChat =
    subPath === "chat" && !isNullOrUndefined(selectedTurnaroundInfo);

  const turnaroundPath = showDetails || showTimeline || showChat;
  const isExportDisabled = isProcessing || turnaroundsSummaryExportSaving;

  return (
    <div className="turnarounds-module">
      <div
        className={`turnarounds-module-header${
          turnaroundPath ? " sub-path" : ""
        }`}
      >
        <div>
          <div className="titles">
            {isNullOrUndefined(selectedTurnaroundInfo) && (
              <>
                <h3 className="badged">
                  {t("turnarounds")} <TitleBadge value={turnaroundsCount} />
                </h3>
                <div>
                  {!isActiveMode && (
                    <span className="capitalized">
                      {formatLongDate(selectedDate)}
                      {selectedDate.isSame(mToday) && (
                        <>&nbsp;&middot;&nbsp;{t("today")}</>
                      )}
                    </span>
                  )}
                  {isActiveMode && (
                    <span className="capitalized">
                      {formatLongDatetime(mNow, airportTimezone)}
                    </span>
                  )}
                </div>
              </>
            )}
            {turnaroundPath && (
              <>
                <h3 className="badged">
                  {t("turnaround")}: {selectedTurnaroundInfo.combinedFlightName}
                  <TitleBadge
                    value={selectedTurnaroundInfo.statusDisplayName}
                  />
                </h3>
                <div>
                  <span className="capitalized">
                    {formatLongDate(selectedTurnaroundInfo.landing)}
                    {selectedDate.isSame(mToday) && (
                      <>&nbsp;&middot;&nbsp;{t("today")}</>
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="actions">
            {showDetails && !isNullOrUndefined(selectedTurnaroundInfo) && (
              <div>
                {selectedTurnaroundInfo.isCompleted && (
                  <button
                    className="secondary"
                    onClick={() => {
                      navigate(
                        `/turnarounds/${selectedTurnaroundInfo.uuid}/timeline`
                      );
                    }}
                  >
                    {t("view_timeline")}
                  </button>
                )}
                {!selectedTurnaroundInfo.isCompleted && (
                  <button
                    className="secondary"
                    onClick={() => {
                      setTimeout(() => {
                        dispatch({
                          type: "setShowOnMap",
                          value: {
                            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
                            item: selectedTurnaroundInfo,
                          },
                        });
                      }, 250);
                      navigate("/map");
                      logAnalyticsEvent(
                        dispatch,
                        ANALYTICS_EVENTS.TURNAROUNDS_DETAILS_SHOW_ON_MAP
                      );
                    }}
                  >
                    {t("show_in_map")}
                  </button>
                )}
              </div>
            )}
            {showTimeline && !isNullOrUndefined(selectedTurnaroundInfo) && (
              <div>
                {selectedTurnaroundInfo.isCompleted && (
                  <button
                    className="secondary"
                    onClick={() => {
                      navigate(`/turnarounds/${selectedTurnaroundInfo.uuid}`);
                      logAnalyticsEvent(
                        dispatch,
                        ANALYTICS_EVENTS.TURNAROUNDS_VIEW_DETAILS
                      );
                    }}
                  >
                    {t("view_details")}
                  </button>
                )}
              </div>
            )}

            {isNullOrUndefined(selectedTurnaroundInfo) && (
              <div className="actions">
                <div>
                  <button
                    className="secondary"
                    onClick={handleExportClick}
                    disabled={isExportDisabled}
                  >
                    <Download />
                    {t("download_csv")}
                  </button>
                </div>
                <div>
                  <ButtonSwitch
                    selectedOption={
                      isActiveMode
                        ? "turnarounds_page_mode_active"
                        : "turnarounds_page_mode_completed"
                    }
                    labelValuePairs={[
                      {
                        value: "turnarounds_page_mode_active",
                      },
                      {
                        value: "turnarounds_page_mode_completed",
                      },
                    ]}
                    onChange={(mode) => {
                      if (mode === "turnarounds_page_mode_active") {
                        SettingsManager.setSetting(
                          SettingNames.TURNAROUNDS_PAGE_MODE.name,
                          TurnaroundsPageMode.ACTIVE
                        );
                      } else if (mode === "turnarounds_page_mode_completed") {
                        SettingsManager.setSetting(
                          SettingNames.TURNAROUNDS_PAGE_MODE.name,
                          TurnaroundsPageMode.COMPLETED
                        );
                      }
                      SettingsManager.updateState(dispatch);
                    }}
                  />
                </div>
                {!isActiveMode && (
                  <div>
                    <ButtonDatePicker
                      label={
                        selectedDate == null
                          ? null
                          : selectedDate.format("MM/DD/YYYY")
                      }
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      minDate={moment(MIN_DATE)}
                      maxDate={moment(mToday).add(2, "month")}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {showTimeline && (
        <div className="turnarounds-module-container tasks">
          <TurnaroundsModuleTasks
            taskInfoList={taskInfoList}
            turnaroundInfo={selectedTurnaroundInfo}
            timezone={airportTimezone}
          />
        </div>
      )}
      {(showDetails || showChat) && (
        <div className="turnarounds-module-container config">
          <TurnaroundsModuleConfig
            turnaroundInfo={selectedTurnaroundInfo}
            timezone={airportTimezone}
            gseTypes={gseTypes}
            showChat={showChat}
          />
        </div>
      )}
      {isNullOrUndefined(selectedTurnaroundInfo) && (
        <div className="turnarounds-module-container">
          <DataTable
            initialSort={TurnaroundsModuleColumns.ScheduledIn.id}
            dataList={dataList}
            columnNames={[
              TurnaroundsModuleColumns.TurnaroundName,
              TurnaroundsModuleColumns.Registration,
              TurnaroundsModuleColumns.ScheduledIn,
              TurnaroundsModuleColumns.EstimatedIn,
              TurnaroundsModuleColumns.ActualIn,
              TurnaroundsModuleColumns.ScheduledOut,
              TurnaroundsModuleColumns.EstimatedOut,
              TurnaroundsModuleColumns.ActualOut,
              TurnaroundsModuleColumns.OriginDestination,
              TurnaroundsModuleColumns.Stand,
              TurnaroundsModuleColumns.Leads,
              TurnaroundsModuleColumns.Remarks,
            ]}
            rowClass={(item) => {
              if (!item.hasValidTurnaroundTime) {
                return " status-invalid";
              }
            }}
            rowActions={rowActions}
            rowClick={hasTurnaroundEditModal ? handleRowClick : null}
            gridColumns={
              "minmax(120px, 240px)  minmax(0,96px)  repeat(3, minmax(0,80px))  repeat(3, minmax(0,80px))  minmax(0,120px)  minmax(0,96px)  minmax(96px,180px) minmax(120px, 1fr) 140px"
            }
            searchQuery={searchQuery}
          />
          <TurnaroundEditModal
            selectedTurnaroundUuid={selectedTurnaroundUuidForEdit}
            onCloseHandler={() => {
              setSelectedTurnaroundUuidForEdit(null);
            }}
            onSaved={() => {
              handleReloadTurnarounds();
            }}
            timezone={airportTimezone}
          />
          {!isNullOrUndefined(turnaroundForAssignment?.uuid) && (
            <AssignLeadsModal
              selectedTurnaroundUuid={turnaroundForAssignment?.uuid}
              onCloseHandler={() => {
                dispatch({
                  type: "setTurnaroundForAssignment",
                  value: null,
                });
              }}
              onSaved={() => {
                handleReloadTurnarounds();
              }}
              timezone={airportTimezone}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default TurnaroundsModule;
