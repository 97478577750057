import { getApiByResourceName } from "./api";

// Create Flight
export async function createFlight(dispatch, flightToCreate) {
  return await getApiByResourceName(
    dispatch,
    `flights`,
    "FlightsCreate",
    "post",
    flightToCreate
  );
}

// GET /airlines
// let user pick an airline
export async function getAirlines(dispatch) {
  return await getApiByResourceName(dispatch, "/airlines", "Airlines", "get");
}

// GET /airlines/{uuid}/airports
// let user pick origin and dest airports
export async function getAirportsForAirline(dispatch, airlineUuid) {
  return await getApiByResourceName(
    dispatch,
    `airlines/${airlineUuid}/airports`,
    "AirportsForAirline",
    "get"
  );
}

// GET /airlines/{uuid}/aircrafts
// let user pick an aircraft
export async function getAircraftsForAirline(dispatch, airlineUuid) {
  return await getApiByResourceName(
    dispatch,
    `airlines/${airlineUuid}/aircrafts`,
    "AircraftsForAirline",
    "get"
  );
}
