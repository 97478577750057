import { ReactComponent as Completed } from "../../assets/operations/status-completed.svg";
import { ReactComponent as CriticalIcon } from "../../assets/critical.svg";
import { TurnaroundStatus } from "../../turnaroundUtils";
import { isBlank } from "../../utils";
import { Fade, Tooltip } from "@mui/material";
import { tooltipLightStyles } from "../../styleUtils";
import { useTranslation } from "react-i18next";

function TurnaroundName(props) {
  const { item } = props;
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        !item.hasValidTurnaroundTime ? (
          <div className="tooltip-content">
            <div className="critical">
              {t("turnaround_time_invalid_message")}
            </div>
          </div>
        ) : null
      }
      placement="top"
      componentsProps={tooltipLightStyles}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      enterDelay={500}
      arrow
    >
      <div className={`turnaround-flights`}>
        <div className="flight-status">
          {!item.hasValidTurnaroundTime && (
            <div className="flight-critical-indicator">
              <div>
                <CriticalIcon />
              </div>
            </div>
          )}
          {item.hasValidTurnaroundTime && (
            <div
              className={`flight-status-icon status-${item.statusCode}${
                item.statusCode === TurnaroundStatus.TurnaroundInProgress
                  ? " pulseOpacity"
                  : ""
              }`}
            >
              {item.statusCode === TurnaroundStatus.TurnaroundComplete && (
                <Completed />
              )}
            </div>
          )}
        </div>
        <div className="flight-names">
          <div>
            {item?.inboundFlightName}
            {!isBlank(item?.outboundFlightName) && <>&nbsp;-&nbsp;</>}
          </div>
          {!isBlank(item?.outboundFlightName) && (
            <div>{item.outboundFlightName}</div>
          )}
        </div>
      </div>
    </Tooltip>
  );
}
export default TurnaroundName;
