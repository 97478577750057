import "./styles.css";
import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { isNullOrUndefined } from "../../utils";

function AlertMessage(props) {
  const { message, handleClose, autoClose } = props;
  if (isNullOrUndefined(message)) return null;
  const messageText = !isNullOrUndefined(message?.messageText)
    ? message?.messageText
    : message;

  // If message is to remain open then do not set duration
  const messageAutoClose = !isNullOrUndefined(message?.remainOpen)
    ? null
    : autoClose;

  const action = (
    <>
      {!isNullOrUndefined(message?.alertAction) && message.alertAction}
      <IconButton
        size="small"
        onClick={handleClose}
        sx={{
          bgcolor: "#4A4C56",
          color: "#FFF",
          "&:hover": {
            backgroundColor: "#1779DA",
          },
          "& button.MuiButtonBase-root": {
            width: "30px",
            height: "30px",
          },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={messageAutoClose}
      open={!isNullOrUndefined(message)}
      onClose={handleClose}
      message={messageText}
      action={action}
    />
  );
}

export default AlertMessage;
