import "./styles.css";
import { useTranslation } from "react-i18next";
import { EMPTY_VALUE } from "../../constants";
import {
  formatDate,
  formatHoursMins,
  formatTime,
  isNullOrUndefined,
} from "../../utils";

function FlightTimeValue(props) {
  const { resolvedFlightTimes, timezone, showPlusDays, showDate } = props;
  const { t } = useTranslation();

  const isLate = resolvedFlightTimes?.timeChangeDiff > 0;
  const isEarly = resolvedFlightTimes?.timeChangeDiff < 0;

  return (
    <div className="flight-time-value">
      <div
        className={`${isLate ? " highlight-negative" : ""}${
          isEarly ? " highlight-positive" : ""
        }`}
      >
        {!isNullOrUndefined(resolvedFlightTimes?.displayTime)
          ? formatTime(resolvedFlightTimes.displayTime, timezone, showPlusDays)
          : EMPTY_VALUE}
        {resolvedFlightTimes?.hasTimeChanged &&
          !isNullOrUndefined(resolvedFlightTimes?.displayTimeType) && (
            <span className="previous-value">
              {formatTime(
                resolvedFlightTimes.scheduledTime,
                timezone,
                showPlusDays
              )}
            </span>
          )}
      </div>
      {resolvedFlightTimes?.hasTimeChanged && !showDate && (
        <div
          className={`duration-value ${isLate ? " highlight-negative" : ""}${
            isEarly ? " highlight-positive" : ""
          }`}
        >
          {isEarly &&
            t("duration_early_web", {
              value: formatHoursMins(
                Math.abs(resolvedFlightTimes.timeChangeDiff)
              ),
            })}
          {isLate &&
            t("duration_late_web", {
              value: formatHoursMins(resolvedFlightTimes.timeChangeDiff),
            })}
        </div>
      )}
      {!isNullOrUndefined(resolvedFlightTimes?.displayTime) && showDate && (
        <div className="date-value">
          {formatDate(resolvedFlightTimes.displayTime, timezone)}
        </div>
      )}
    </div>
  );
}
export default FlightTimeValue;
