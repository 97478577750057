import { useState } from "react";
import { isValidPassword } from "../../utils";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import { ReactComponent as EyeHidden } from "../../assets/eye-hidden.svg";
import { changePassword } from "../../api";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";
import PasswordRequirements from "../Login/PasswordRequirements";

function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { changePasswordSaving } = mainContext;
  const [previousPassword, setPreviousPassword] = useState("");
  const [proposedPassword, setProposedPassword] = useState("");
  const [passwordToggle, setPasswordToggle] = useState("password");
  const [proposedPasswordToggle, setProposedPasswordToggle] =
    useState("password");

  async function handleUpdatePassword() {
    const passwordEntity = {
      previousPassword: previousPassword,
      proposedPassword: proposedPassword,
    };
    const result = await changePassword(passwordEntity, dispatch);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("password_updated_message"),
      });
    }
  }
  const isValid = () => {
    return (
      isValidPassword(previousPassword) && isValidPassword(proposedPassword)
    );
  };

  const togglePassword = () => {
    setPasswordToggle(passwordToggle === "password" ? "text" : "password");
  };
  const toggleProposedPassword = () => {
    setProposedPasswordToggle(
      proposedPasswordToggle === "password" ? "text" : "password"
    );
  };

  const buttonDisabled = !isValid() || changePasswordSaving;

  return (
    <div className="edit-profile">
      <div>
        <div>
          <div className="settings-form-header">
            <h3>{t("change_password")}</h3>
            <span>{t("protect_account")}</span>
          </div>
        </div>
        <div className="settings-form">
          <div className="field-row ">
            <div>
              <label>{t("current_password")}</label>
              <div className="password-input">
                <input
                  type={passwordToggle === "password" ? "password" : "text"}
                  value={previousPassword}
                  onChange={(e) => setPreviousPassword(e.target.value)}
                />
                <div
                  className="password-toggle"
                  title={
                    passwordToggle === "password"
                      ? t("show_password")
                      : t("hide_password")
                  }
                  onClick={togglePassword}
                >
                  {passwordToggle === "password" ? <Eye /> : <EyeHidden />}
                </div>
              </div>
            </div>
          </div>
          <div className="field-row ">
            <div>
              <label>{t("new_password")}</label>
              <div className="password-input">
                <input
                  type={
                    proposedPasswordToggle === "password" ? "password" : "text"
                  }
                  value={proposedPassword}
                  onChange={(e) => setProposedPassword(e.target.value)}
                />
                <div
                  className="password-toggle"
                  title={
                    proposedPasswordToggle === "password"
                      ? t("show_password")
                      : t("hide_password")
                  }
                  onClick={toggleProposedPassword}
                >
                  {proposedPasswordToggle === "password" ? (
                    <Eye />
                  ) : (
                    <EyeHidden />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="field-row ">
            <div>
              <PasswordRequirements password={proposedPassword} />
            </div>
          </div>
          <div className="field-row actions">
            <div>
              <button onClick={handleUpdatePassword} disabled={buttonDisabled}>
                {t("update")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
