import { Modal } from "@mui/material";
import {
  deepCopy,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";
import { cancelFlight, getAircrafts, patchFlights } from "../../api";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import ButtonDatePicker from "../ButtonDatePicker";
import TimeInput from "../TimeRangeInput/TimeInput";
import { MIN_DATE } from "../../constants";
import moment from "moment-timezone";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import RegistrationSelector from "../RegistrationSelector";

export default function FlightsEditModal(props) {
  const { onCloseHandler, onSaved, selectedFlight, timezone } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, aircrafts } = mainContext;
  const [isProcessing, setIsProcessing] = useState(false);
  const [flightForEdit, setFlightForEdit] = useState(null);

  const mToday = moment().tz(timezone).startOf("day");
  const hasChanges =
    flightForEdit?.estimatedInHasChanged ||
    flightForEdit?.estimatedOutHasChanged ||
    flightForEdit?.registrationHasChanged;

  useEffect(() => {
    if (!isNullOrUndefined(selectedFlight)) {
      const sourceFlight = deepCopy(selectedFlight);
      const flightInfo = {
        _source: sourceFlight,
        estimatedIn: !isNullOrUndefined(sourceFlight.estimatedIn)
          ? sourceFlight.estimatedIn
          : !isNullOrUndefined(sourceFlight.scheduledIn)
          ? sourceFlight.scheduledIn
          : null,
        estimatedOut: !isNullOrUndefined(sourceFlight.estimatedOut)
          ? sourceFlight.estimatedOut
          : !isNullOrUndefined(sourceFlight.scheduledOut)
          ? sourceFlight.scheduledOut
          : null,
        uuid: sourceFlight.uuid,
        registration: sourceFlight.registration,
        estimatedInHasChanged: false,
        estimatedOutHasChanged: false,
        registrationHasChanged: false,
      };

      setFlightForEdit(flightInfo);
    }
  }, [selectedFlight]);

  // Load supporting data
  useEffect(() => {
    getAircrafts(dispatch);
  }, [dispatch]);

  if (!isEmptyList(aircrafts)) {
    sortByField(aircrafts, "registration");
  }

  const handleClose = () => {
    setFlightForEdit(null);
    setIsProcessing(false);
    if (!isNullOrUndefined(onCloseHandler)) {
      onCloseHandler();
    }
  };

  async function handleSave() {
    setIsProcessing(true);
    const patchRequest = {
      uuid: flightForEdit.uuid,
    };
    if (flightForEdit.estimatedInHasChanged) {
      patchRequest.inboundEstimatedArrivalTime = flightForEdit.estimatedIn;
    }
    if (flightForEdit.estimatedOutHasChanged) {
      patchRequest.outboundEstimatedDepartureTime = flightForEdit.estimatedOut;
    }
    if (flightForEdit.registrationHasChanged) {
      patchRequest.registration = flightForEdit.registration;
    }

    const result = await patchFlights(dispatch, patchRequest);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: (
          <div>
            <div>
              {t("saved_web", {
                name: selectedFlight.flightName,
              })}
            </div>
            <div>{t("processing_request_message")}</div>
          </div>
        ),
      });
      if (!isNullOrUndefined(onSaved)) {
        onSaved();
      }
    } else {
      dispatch({
        type: "setGlobalError",
        globalError: t("request_error_msg"),
      });
    }
  }

  // Confirm before canceling the flight
  async function handleCancelFlight() {
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: (
          <div className="confirmation-message">
            <div>{selectedFlight.flightName}</div>
            <div>{t("cancel_flight_message")}</div>
          </div>
        ),
        actionOK: () => {
          confirmFlightCanceled(selectedFlight);
          dismissConfirmation();
        },
        actionCancel: () => {
          dismissConfirmation();
        },
        actionLabel: t("confirm_change"),
        cancelLabel: t("cancel"),
      },
    });
  }

  async function confirmFlightCanceled(selectedFlight) {
    const result = await cancelFlight(dispatch, selectedFlight.uuid);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: (
          <div>
            <div>
              {t("saved_web", {
                name: selectedFlight.flightName,
              })}
            </div>
            <div>{t("processing_request_message")}</div>
          </div>
        ),
      });
      if (!isNullOrUndefined(onSaved)) {
        onSaved();
      }
    }
  }

  const dismissConfirmation = () => {
    dispatch({
      type: "setConfirmation",
      confirmation: null,
    });
  };

  const hasCancelFlight = userHasAnyResourceAction(
    currentUser,
    ResourceActions.CancelFlight
  );

  return (
    <div>
      <Modal open={!isNullOrUndefined(selectedFlight)} onClose={handleClose}>
        <div className="modal modal-medium flight-edit-modal">
          <div>
            <div className="modal-header">
              <div>
                <h2>
                  {!isNullOrUndefined(selectedFlight?.flightName) && (
                    <>{`${t("flight_non_cap")}: ${
                      selectedFlight?.flightName
                    }`}</>
                  )}
                </h2>
                {!isNullOrUndefined(selectedFlight) && (
                  <>
                    {selectedFlight.isInbound && (
                      <span>
                        {t("inbound")}&nbsp;&middot;&nbsp;
                        {`${selectedFlight.origin}-${selectedFlight.destination}`}
                      </span>
                    )}
                    {!selectedFlight.isInbound && (
                      <span>
                        {t("outbound")}&nbsp;&middot;&nbsp;
                        {`${selectedFlight.origin}-${selectedFlight.destination}`}
                      </span>
                    )}
                  </>
                )}
              </div>

              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              {!isNullOrUndefined(flightForEdit) && (
                <div className="flight-edit-modal-rows">
                  {/** Estimated In */}
                  {!isNullOrUndefined(flightForEdit.estimatedIn) &&
                    selectedFlight.isInbound && (
                      <div className="flight-edit-modal-row">
                        <div className="time-configuration">
                          <div>
                            <label>{t("estimated_time_arrival_abbr")}</label>
                          </div>
                          <div className="time-configuration-time-value">
                            <div className="time-input-container">
                              <div>
                                <TimeInput
                                  timeValue={flightForEdit.estimatedIn}
                                  timezone={timezone}
                                  onTimeChange={(dateStrVal) => {
                                    const flightToUpdate =
                                      deepCopy(flightForEdit);
                                    const newVal =
                                      moment(dateStrVal).tz(timezone);
                                    const updatedVal = moment(
                                      flightToUpdate.estimatedIn
                                    ).tz(timezone);
                                    updatedVal.set("hours", newVal.hours());
                                    updatedVal.set("minutes", newVal.minutes());
                                    flightToUpdate.estimatedIn =
                                      updatedVal.toISOString();
                                    flightToUpdate.estimatedInHasChanged = true;
                                    setFlightForEdit(flightToUpdate);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="time-configuration-date-value">
                            <ButtonDatePicker
                              label={moment(flightForEdit.estimatedIn)
                                .tz(timezone)
                                .format("MM/DD/YYYY")}
                              value={moment(flightForEdit.estimatedIn).tz(
                                timezone
                              )}
                              onChange={(newValue) => {
                                // update
                                const flightToUpdate = deepCopy(flightForEdit);
                                const updatedVal = moment(
                                  flightToUpdate.estimatedIn
                                ).tz(timezone);
                                updatedVal.set("date", newValue.date());
                                updatedVal.set("month", newValue.month());
                                updatedVal.set("year", newValue.year());
                                flightToUpdate.estimatedIn =
                                  updatedVal.toISOString();
                                flightToUpdate.estimatedInHasChanged = true;
                                setFlightForEdit(flightToUpdate);
                              }}
                              minDate={moment(MIN_DATE)}
                              maxDate={moment(mToday).add(2, "month")}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {/** Estimated Out */}
                  {!isNullOrUndefined(flightForEdit.estimatedOut) &&
                    !selectedFlight.isInbound && (
                      <div className="flight-edit-modal-row">
                        <div className="time-configuration">
                          <div>
                            <label>{t("estimated_time_departure_abbr")}</label>
                          </div>
                          <div className="time-configuration-time-value">
                            <div className="time-input-container">
                              <div>
                                <TimeInput
                                  timeValue={flightForEdit.estimatedOut}
                                  timezone={timezone}
                                  onTimeChange={(dateStrVal) => {
                                    const flightToUpdate =
                                      deepCopy(flightForEdit);
                                    const newVal =
                                      moment(dateStrVal).tz(timezone);
                                    const updatedVal = moment(
                                      flightToUpdate.estimatedOut
                                    ).tz(timezone);
                                    updatedVal.set("hours", newVal.hours());
                                    updatedVal.set("minutes", newVal.minutes());
                                    flightToUpdate.estimatedOut =
                                      updatedVal.toISOString();
                                    flightToUpdate.estimatedOutHasChanged = true;
                                    setFlightForEdit(flightToUpdate);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="time-configuration-date-value">
                            <ButtonDatePicker
                              label={moment(flightForEdit.estimatedOut)
                                .tz(timezone)
                                .format("MM/DD/YYYY")}
                              value={moment(flightForEdit.estimatedOut).tz(
                                timezone
                              )}
                              onChange={(newValue) => {
                                // update
                                const flightToUpdate = deepCopy(flightForEdit);
                                const updatedVal = moment(
                                  flightToUpdate.estimatedOut
                                ).tz(timezone);
                                updatedVal.set("date", newValue.date());
                                updatedVal.set("month", newValue.month());
                                updatedVal.set("year", newValue.year());
                                flightToUpdate.estimatedOut =
                                  updatedVal.toISOString();
                                flightToUpdate.estimatedOutHasChanged = true;
                                setFlightForEdit(flightToUpdate);
                              }}
                              minDate={moment(MIN_DATE)}
                              maxDate={moment(mToday).add(2, "month")}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {/** Registration */}
                  {!isNullOrUndefined(flightForEdit.registration) && (
                    <div className="flight-edit-modal-row">
                      <div className="registration-configuration">
                        <div>
                          <label>{t("registration")}</label>
                        </div>
                        <div>
                          <RegistrationSelector
                            onSelect={(value) => {
                              // update
                              const flightToUpdate = deepCopy(flightForEdit);
                              flightToUpdate.registration = value.registration;
                              flightToUpdate.registrationHasChanged = true;
                              setFlightForEdit(flightToUpdate);
                            }}
                            aircrafts={aircrafts}
                            value={flightForEdit.registration}
                            allowClear={false}
                          />

                          {/*
                          <select
                            style={selectInputFix}
                            value={flightForEdit.registration}
                            onChange={(e) => {
                              // update
                              const flightToUpdate = deepCopy(flightForEdit);
                              flightToUpdate.registration = e.target.value;
                              flightToUpdate.registrationHasChanged = true;
                              setFlightForEdit(flightToUpdate);
                            }}
                          >
                            {aircrafts &&
                              aircrafts.map((aircraft) => (
                                <option
                                  value={aircraft.registration}
                                  key={aircraft.registration}
                                >
                                  {aircraft.registration}
                                </option>
                              ))}
                          </select>
                          */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div className="action-buttons">
                <div>
                  {hasCancelFlight && (
                    <button
                      className="danger"
                      onClick={handleCancelFlight}
                      disabled={isProcessing}
                    >
                      {t("cancel_flight")}
                    </button>
                  )}
                </div>
                <div>
                  <button
                    onClick={handleSave}
                    disabled={isProcessing || !hasChanges}
                  >
                    {t("save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
