import { getLocalMoment, isBlank, isNullOrUndefined } from "./utils";

export const SEARCH_PARAM_DATE_FORMAT = "YYYY-MM-DD";

export function getDayInitial(dateObj) {
  return getDayAbbreviation(dateObj).substring(0, 1);
}

export function getDayAbbreviation(dateObj) {
  return getLocalMoment(dateObj)
    .format("ddd")
    .toUpperCase()
    .replaceAll(".", "");
}

// Returns true if d1 after d2
export function isDateAfter(d1, d2) {
  if (isNullOrUndefined(d1) || isNullOrUndefined(d2)) return false;
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  return date1.getTime() > date2.getTime();
}

export function isValidDateString(dateString) {
  return !isBlank(dateString) && !isNaN(Date.parse(dateString));
}
