import "./variables.css";
import "./App.css";
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AlertMessage from "./components/AlertMessage";
import Login from "./components/Login";
import { useMainContext, useMainDispatchContext } from "./MainContext";
import { isBlank, isEmptyList, isNullOrUndefined } from "./utils";
import MainConsole from "./components/MainConsole";
import Confirmation from "./components/Confirmation";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CookieManager } from "./cookies";
import Analytics from "./components/Analytics";
import "./i18n";
import { ALERT_DURATION } from "./constants";
import { useTranslation } from "react-i18next";
import { setDefaultLanguage } from "./i18n";
import AlertMessageModal from "./components/AlertMessageModal";

function App() {
  const { i18n } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, alertMessage, globalError, globalErrorList } =
    mainContext;

  useEffect(() => {
    // Language
    setDefaultLanguage();
  }, []);

  useEffect(() => {
    dispatch({
      type: "setAlertMessage",
      alertMessage: alertMessage,
    });
  }, [dispatch, alertMessage]);

  useEffect(() => {
    dispatch({
      type: "setGlobalError",
      globalError: globalError,
      globalErrorList: globalErrorList,
    });
  }, [dispatch, globalError, globalErrorList]);

  const handleClose = () => {
    // clear alert message to close
    dispatch({
      type: "setAlertMessage",
      alertMessage: null,
    });
  };

  const handleCloseError = () => {
    // clear message to close
    dispatch({
      type: "setGlobalError",
      globalError: null,
    });
  };

  const accessToken = CookieManager.getCookieValue("accessToken");
  const isLoginRequired =
    isNullOrUndefined(currentUser) && isBlank(accessToken);

  const showGlobalErrorList =
    !isEmptyList(globalErrorList) && globalErrorList.length > 1;

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18n.language}
    >
      <Analytics />
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={
              isLoginRequired ? (
                <Navigate to="/login" />
              ) : (
                <Navigate
                  to="/dashboard"
                  element={<MainConsole section="dashboard" />}
                />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={<MainConsole section="dashboard" />}
          />
          <Route path="/flights" element={<MainConsole section="flights" />} />
          <Route path="/map" element={<MainConsole section="map" />} />
          <Route path="/chat" element={<MainConsole section="chat" />} />
          <Route
            path="/live-roster"
            element={<MainConsole section="live-roster" />}
          />
          <Route
            path="/turnarounds"
            element={<MainConsole section="turnarounds" />}
          />
          <Route
            path="/turnarounds/:turnaroundUuid"
            element={<MainConsole section="turnarounds-details" />}
          />
          <Route
            path="/turnarounds/:turnaroundUuid/timeline"
            element={<MainConsole section="turnarounds-timeline" />}
          />
          <Route
            path="/turnarounds/:turnaroundUuid/chat"
            element={<MainConsole section="turnarounds-chat" />}
          />
          <Route
            path="/allocations"
            element={<MainConsole section="allocations" />}
          />
          <Route path="/users" element={<MainConsole section="users" />} />
          <Route
            path="/vehicles"
            element={<MainConsole section="vehicles" />}
          />
          <Route
            path="/templates"
            element={<MainConsole section="templates" />}
          />
          <Route
            path="/stand-assignments"
            element={<MainConsole section="stand-assignments" />}
          />
          <Route
            path="/crew-shifts"
            element={<MainConsole section="crew-shifts" />}
          />
          {/** Alerts are temp disabled
          <Route path="/alerts" element={<MainConsole section="alerts" />} />
          **/}
          <Route
            path="/notifications"
            element={<MainConsole section="notifications" />}
          />
          <Route
            path="/settings"
            element={<MainConsole section="settings" />}
          />
          <Route
            path="/settings/:section"
            element={<MainConsole section="settings" />}
          />
          <Route
            path="/performance"
            element={<MainConsole section="performance" />}
          />
          <Route
            path="/utilization"
            element={<MainConsole section="utilization" />}
          />
          <Route
            path="/leaderboard"
            element={<MainConsole section="leaderboard" />}
          />
        </Routes>

        <AlertMessage
          message={alertMessage}
          handleClose={handleClose}
          autoClose={ALERT_DURATION.DEFAULT}
        />

        <AlertMessage
          message={!showGlobalErrorList ? globalError : null}
          handleClose={handleCloseError}
          autoClose={ALERT_DURATION.ERROR}
        />

        {showGlobalErrorList && (
          <AlertMessageModal
            alertMessages={globalErrorList}
            onClose={() => {
              dispatch({
                type: "setGlobalError",
                globalError: null,
                globalErrorList: null,
              });
            }}
          />
        )}

        <Confirmation />
      </div>
    </LocalizationProvider>
  );
}

export default App;
