import { useReducer } from "react";
import { MainContext, MainDispatchContext } from "./MainContext";
import { DEFAULT_MAP_FILTERS } from "./mapUtils";
import { deepCopy } from "./utils";

export const initialState = {
  analyticsEvent: null,
  currentUser: null,
  mediaSignatures: [],
  dynamicEnv: {},
  dynamicEnvLoading: false,
  alertMessage: null,
  notification: null,
  globalError: null,
  globalErrorList: null,
  confirmation: null,
  confirmationProcessing: false,
  codeSent: false,
  passwordReset: false,
  signedUp: false,
  currentPage: "dashboard",
  crewList: [],
  crewListLoading: false,
  weather: null,
  weatherLoading: false,
  users: [],
  usersLoading: false,
  userGroups: [],
  userGroupsLoading: false,
  certifications: [],
  certificationsLoading: false,
  alerts: [],
  alertsLoading: false,
  notifications: [],
  notificationsLoading: false,
  notificationsLastReadSaving: false,
  turnaroundDetails: null,
  turnaroundDetailsLoading: null,
  turnaroundsSummary: [],
  turnaroundsSummaryLoading: false,
  turnaroundsSummarySearch: [],
  turnaroundsSummarySearchLoading: false,
  turnaroundsWithCriteria: [],
  turnaroundsWithCriteriaLoading: false,
  turnaroundsForDispatch: [],
  turnaroundsForDispatchLoading: false,
  turnaroundDispatchToEdit: null,
  turnaroundProfile: null,
  turnaroundProfileSaving: false,
  turnaroundsFlightNumberPairs: [],
  turnaroundsFlightNumberPairsLoading: false,
  turnaroundTemplates: [],
  turnaroundTemplatesLoading: false,
  turnaroundTemplate: null,
  turnaroundTemplateSaving: false,
  turnaroundAllocations: [],
  turnaroundAllocationsLoading: false,
  turnaroundStandAssignments: [],
  turnaroundStandAssignmentsLoading: false,
  flights: [],
  flightsLoading: false,
  vehicles: [],
  vehiclesLoading: false,
  gses: [],
  gsesLoading: false,
  gsesSaving: false,
  gseTypes: [],
  gseTypesLoading: false,
  deployment: null,
  deploymentSaving: false,
  deployments: [],
  deploymentsLoading: false,
  changePassword: null,
  changePasswordSaving: false,
  trackers: [],
  trackersLoading: false,
  alertsLastRead: null,
  alertsLastReadSaving: false,
  userSaving: false,
  searchQuery: null,
  searchbarDisabled: false,
  positions: {},
  positionsLoading: false,
  aircraftPositionPath: {},
  aircraftPositionPathLoading: false,
  trackerPositionPath: {},
  trackerPositionPathLoading: false,
  userPositionPath: {},
  userPositionPathLoading: false,
  flightsToRender: [],
  flightsToRenderLoading: false,
  airportDetail: {},
  airportDetailLoading: false,
  turnaroundStands: {},
  turnaroundStandsSaving: false,
  showOnMap: null,
  moveMapToPosition: null,
  profilePictureUpload: null,
  profilePictureUploadLoading: false,
  profilePictureCacheBuster: null,
  aircraftStand: null,
  aircraftStandSaving: false,
  alertPreferences: null,
  alertPreferencesLoading: false,
  alertPreferencesSaving: false,
  moveAircraftReminder: null,
  moveAircraftRequest: null,
  isMoveAircraftPanelOpen: false,
  selectedChannelId: null,
  selectedChatRequest: null,
  showAircraftTypeSelector: false,
  isRefreshPaused: false,
  showMapInfo: false,
  showMapRoutes: true,
  itemsToWhitelist: [],
  userToAssign: null,
  turnaroundLabelsPreview: null,
  turnaroundLabelsPreviewSaving: false,
  turnaroundOperationAckStatus: null,
  turnaroundOperationAckStatusSaving: null,
  turnaroundDetailsForPatch: null,
  turnaroundDetailsForPatchLoading: false,
  turnaroundPatchSaving: null,
  turnaroundDetailsForConfirm: null,
  turnaroundDetailsForConfirmLoading: false,
  pinMarker: null,
  crewShifts: [],
  crewShiftsSaving: false,
  operationTypes: [],
  operationTypesLoading: false,
  performanceOperations: [],
  performanceOperationsLoading: [],
  performanceCrew: [],
  performanceCrewLoading: [],
  departments: [],
  departmentsLoading: false,
  unreadChatChannels: 0,
  unreadTurnaroundChatChannels: 0,
  mapFilters: DEFAULT_MAP_FILTERS,
  crewShiftsExport: null,
  crewShiftsExportSaving: false,
  turnaroundsSummaryExport: null,
  turnaroundsSummaryExportSaving: false,
  localSettings: null,
  turnaroundForAssignment: null,
  aircrafts: null,
  aircraftsLoading: false,
  flightsSummarySearch: null,
  flightsSummarySearchLoading: null,
  flightsPatchSaving: false,
  flightsCancel: null,
  flightsCancelSaving: null,
  airlines: null,
  airlinesLoading: false,
  aircraftsForAirline: null,
  aircraftsForAirlineLoading: false,
  airportsForAirline: null,
  airportsForAirlineLoading: false,
  flightsCreate: null,
  flightsCreateSaving: false,
};

export function mainReducer(state, action) {
  switch (action.type) {
    case "setAnalyticsEvent":
      return {
        ...state,
        analyticsEvent: action.value,
      };
    case "setCurrentUser":
      return {
        ...state,
        currentUser: action.currentUser,
      };
    case "setGlobalError":
      return {
        ...state,
        globalError: action.globalError,
        globalErrorList: action.globalErrorList,
      };
    case "setAlertMessage":
      return {
        ...state,
        alertMessage: action.alertMessage,
      };
    case "setNotification":
      return {
        ...state,
        notification: action.notification,
      };
    case "setCodeSent":
      return {
        ...state,
        codeSent: action.codeSent,
      };
    case "setPasswordReset":
      return {
        ...state,
        passwordReset: action.passwordReset,
      };
    case "setSignedUp":
      return {
        ...state,
        signedUp: action.signedUp,
      };
    case "setVerifiedSignUp":
      return {
        ...state,
        verifiedSignUp: action.verifiedSignUp,
      };
    case "setCurrentPage":
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case "setCrewList":
      return {
        ...state,
        crewList: action.crewList,
      };
    case "setCrewListLoading":
      return {
        ...state,
        crewListLoading: action.crewListLoading,
      };
    case "setWeather":
      return {
        ...state,
        weather: action.value,
      };
    case "setWeatherLoading":
      return {
        ...state,
        weatherLoading: action.value,
      };
    case "setUsers":
      return {
        ...state,
        users: action.value,
      };
    case "setUsersLoading":
      return {
        ...state,
        usersLoading: action.value,
      };
    case "setUserGroups":
      return {
        ...state,
        userGroups: action.value,
      };
    case "setUserGroupsLoading":
      return {
        ...state,
        userGroupsLoading: action.value,
      };
    case "setCertifications":
      return {
        ...state,
        certifications: action.value,
      };
    case "setCertificationsLoading":
      return {
        ...state,
        certificationsLoading: action.value,
      };
    case "setUserSaving":
      return {
        ...state,
        userSaving: action.value,
      };
    case "setGsesSaving":
      return {
        ...state,
        gsesSaving: action.value,
      };
    case "setConfirmation":
      return {
        ...state,
        confirmation: action.confirmation,
        confirmationProcessing: false,
      };
    case "setConfirmationProcessing":
      return {
        ...state,
        confirmationProcessing: action.confirmationProcessing,
      };
    case "setNotifications":
      return {
        ...state,
        notifications: action.value,
      };
    case "setNotificationsLoading":
      return {
        ...state,
        notificationsLoading: action.value,
      };
    case "setNotificationsLastReadSaving":
      return {
        ...state,
        notificationsLastReadSaving: action.value,
      };
    case "setAlerts":
      return {
        ...state,
        alerts: action.value,
      };
    case "setAlertsLoading":
      return {
        ...state,
        alertsLoading: action.value,
      };
    case "appendAlert":
      return {
        ...state,
        alerts: [...state.alerts, action.value],
      };
    case "setTurnaroundsSummary":
      return {
        ...state,
        turnaroundsSummary: action.value,
        flightsToRender: action.value,
      };
    case "setTurnaroundsSummaryLoading":
      return {
        ...state,
        turnaroundsSummaryLoading: action.value,
      };
    case "setTurnaroundsSummarySearch":
      return {
        ...state,
        turnaroundsSummarySearch: action.value,
      };
    case "setTurnaroundsSummarySearchLoading":
      return {
        ...state,
        turnaroundsSummarySearchLoading: action.value,
      };
    case "setFlightsSummarySearch":
      return {
        ...state,
        flightsSummarySearch: action.value,
      };
    case "setFlightsSummarySearchLoading":
      return {
        ...state,
        flightsSummarySearchLoading: action.value,
      };
    case "setFlightsPatchSaving":
      return {
        ...state,
        flightsPatchSaving: action.value,
      };
    case "setFlightsCancel":
      return {
        ...state,
        flightsCancel: action.value,
      };
    case "setFlightsCancelSaving":
      return {
        ...state,
        flightsCancelSaving: action.value,
      };
    case "setTurnaroundDetails":
      return {
        ...state,
        turnaroundDetails: action.value,
      };
    case "setTurnaroundDetailsLoading":
      return {
        ...state,
        turnaroundDetailsLoading: action.value,
      };
    case "setTurnaroundsWithCriteria":
      return {
        ...state,
        turnaroundsWithCriteria: action.value,
      };
    case "setTurnaroundsWithCriteriaLoading":
      return {
        ...state,
        turnaroundsWithCriteriaLoading: action.value,
      };
    case "setTurnaroundsForDispatch":
      return {
        ...state,
        turnaroundsForDispatch: action.value,
      };
    case "setTurnaroundsForDispatchLoading":
      return {
        ...state,
        turnaroundsForDispatchLoading: action.value,
      };
    case "setTurnaroundDispatchToEdit":
      return {
        ...state,
        turnaroundDispatchToEdit: action.value,
      };
    case "setTurnaroundsFlightNumberPairs":
      return {
        ...state,
        turnaroundsFlightNumberPairs: action.value,
      };
    case "setTurnaroundsFlightNumberPairsLoading":
      return {
        ...state,
        turnaroundsFlightNumberPairsLoading: action.value,
      };
    case "setTurnaroundTemplates":
      return {
        ...state,
        turnaroundTemplates: action.value,
      };
    case "setTurnaroundTemplatesLoading":
      return {
        ...state,
        turnaroundTemplatesLoading: action.value,
      };
    case "setTurnaroundTemplate":
      return {
        ...state,
        turnaroundTemplate: action.value,
      };
    case "setTurnaroundTemplateSaving":
      return {
        ...state,
        turnaroundTemplateSaving: action.value,
      };
    case "setTurnaroundProfile":
      return {
        ...state,
        turnaroundProfile: action.value,
      };
    case "setTurnaroundProfileSaving":
      return {
        ...state,
        turnaroundProfileLoading: action.value,
      };
    case "setTurnaroundAllocations":
      return {
        ...state,
        turnaroundAllocations: action.value,
      };
    case "setTurnaroundAllocationsLoading":
      return {
        ...state,
        turnaroundAllocationsLoading: action.value,
      };
    case "setTurnaroundStandAssignments":
      return {
        ...state,
        turnaroundStandAssignments: action.value,
      };
    case "setTurnaroundStandAssignmentsLoading":
      return {
        ...state,
        turnaroundStandAssignmentsLoading: action.value,
      };
    case "setFlights":
      return {
        ...state,
        flights: action.value,
      };
    case "setFlightsLoading":
      return {
        ...state,
        flightsLoading: action.value,
      };
    case "setVehicles":
      return {
        ...state,
        vehicles: action.value,
      };
    case "setVehiclesLoading":
      return {
        ...state,
        vehiclesLoading: action.value,
      };
    case "setGses":
      return {
        ...state,
        gses: action.value,
      };
    case "setGsesLoading":
      return {
        ...state,
        gsesLoading: action.value,
      };
    case "setGseTypes":
      return {
        ...state,
        gseTypes: action.value,
      };
    case "setGseTypesLoading":
      return {
        ...state,
        gseTypesLoading: action.value,
      };
    case "setDeployment":
      return {
        ...state,
        deployment: action.value,
      };
    case "setDeploymentSaving":
      return {
        ...state,
        deploymentSaving: action.value,
      };
    case "setDeployments":
      return {
        ...state,
        deployments: action.value,
      };
    case "setDeploymentsLoading":
      return {
        ...state,
        deploymentsLoading: action.value,
      };
    case "setTrackers":
      return {
        ...state,
        trackers: action.value,
      };
    case "setTrackersLoading":
      return {
        ...state,
        trackersLoading: action.value,
      };
    case "setAlertsLastRead":
      return {
        ...state,
        alertsLastRead: action.value,
      };
    case "setAlertsLastReadSaving":
      return {
        ...state,
        alertsLastReadSaving: action.value,
      };
    case "setChangePassword":
      return {
        ...state,
        changePassword: action.value,
      };
    case "setChangePasswordSaving":
      return {
        ...state,
        changePasswordSaving: action.value,
      };
    case "setSearchQuery":
      return {
        ...state,
        searchQuery: action.value,
      };
    case "setSearchbarDisabled":
      return {
        ...state,
        searchbarDisabled: action.value,
      };
    case "setPositions":
      return {
        ...state,
        positions: action.value,
      };
    case "setPositionsLoading":
      return {
        ...state,
        positionsLoading: action.value,
      };
    case "setAircraftPositionPath":
      return {
        ...state,
        aircraftPositionPath: action.value,
      };
    case "setAircraftPositionPathLoading":
      return {
        ...state,
        aircraftPositionPathLoading: action.value,
      };
    case "setTrackerPositionPath":
      return {
        ...state,
        trackerPositionPath: action.value,
      };
    case "setTrackerPositionPathLoading":
      return {
        ...state,
        trackerPositionPathLoading: action.value,
      };
    case "setUserPositionPath":
      return {
        ...state,
        userPositionPath: action.value,
      };
    case "setUserPositionPathLoading":
      return {
        ...state,
        userPositionPathLoading: action.value,
      };
    case "setFlightsToRender":
      return {
        ...state,
        flightsToRender: action.value,
      };
    case "setFlightsToRenderLoading":
      return {
        ...state,
        flightsToRenderLoading: action.value,
      };
    case "setMediaSignatures":
      return {
        ...state,
        mediaSignatures: action.value,
      };
    case "setDynamicEnv":
      return {
        ...state,
        dynamicEnv: action.value,
      };
    case "setDynamicEnvLoading":
      return {
        ...state,
        dynamicEnvLoading: action.value,
      };
    case "setAirportDetail":
      return {
        ...state,
        airportDetail: action.value,
      };
    case "setAirportDetailLoading":
      return {
        ...state,
        airportDetailLoading: action.value,
      };
    case "setTurnaroundStands":
      return {
        ...state,
        turnaroundStands: action.value,
      };
    case "setTurnaroundStandsSaving":
      return {
        ...state,
        turnaroundStandsSaving: action.value,
      };
    case "setShowOnMap":
      return {
        ...state,
        showOnMap: action.value,
      };
    case "setMoveMapToPosition":
      return {
        ...state,
        moveMapToPosition: action.value,
      };
    case "setProfilePictureUpload":
      return {
        ...state,
        profilePictureUpload: action.value,
      };
    case "setProfilePictureUploadLoading":
      return {
        ...state,
        profilePictureUploadLoading: action.value,
      };
    case "setProfilePictureCacheBuster":
      return {
        ...state,
        profilePictureCacheBuster: action.value,
      };
    case "setAircraftStand":
      return {
        ...state,
        aircraftStand: action.value,
      };
    case "setAircraftStandSaving":
      return {
        ...state,
        aircraftStandSaving: action.value,
      };
    case "setAlertPreferences":
      return {
        ...state,
        alertPreferences: action.value,
      };
    case "setAlertPreferencesSaving":
      return {
        ...state,
        alertPreferencesSaving: action.value,
      };
    case "setAlertPreferencesLoading":
      return {
        ...state,
        alertPreferencesLoading: action.value,
      };
    case "setMoveAircraftReminder":
      return {
        ...state,
        moveAircraftReminder: action.value,
      };
    case "setMoveAircraftRequest":
      return {
        ...state,
        moveAircraftRequest: action.value,
      };
    case "setIsMoveAircraftPanelOpen":
      return {
        ...state,
        isMoveAircraftPanelOpen: action.value,
      };
    case "setSelectedChannelId":
      return {
        ...state,
        selectedChannelId: action.value,
      };
    case "setSelectedChatRequest":
      return {
        ...state,
        selectedChatRequest: action.value,
      };
    case "setShowAircraftTypeSelector":
      return {
        ...state,
        showAircraftTypeSelector: action.value,
      };
    case "setIsRefreshPaused":
      return {
        ...state,
        isRefreshPaused: action.value,
      };
    case "setShowMapInfo":
      return {
        ...state,
        showMapInfo: action.value,
      };
    case "setShowMapRoutes":
      return {
        ...state,
        showMapRoutes: action.value,
      };
    case "setUserToAssign":
      return {
        ...state,
        userToAssign: action.value,
      };
    case "setTurnaroundLabelsPreview":
      return {
        ...state,
        turnaroundLabelsPreview: action.value,
      };
    case "setTurnaroundLabelsPreviewSaving":
      return {
        ...state,
        turnaroundLabelsPreviewSaving: action.value,
      };
    case "setTurnaroundOperationAckStatus":
      return {
        ...state,
        turnaroundOperationAckStatus: action.value,
      };
    case "setTurnaroundOperationAckStatusSaving":
      return {
        ...state,
        turnaroundOperationAckStatusSaving: action.value,
      };
    case "setTurnaroundDetailsForPatch":
      return {
        ...state,
        turnaroundDetailsForPatch: action.value,
      };
    case "setTurnaroundDetailsForPatchLoading":
      return {
        ...state,
        turnaroundDetailsForPatchLoading: action.value,
      };
    case "setTurnaroundPatchSaving":
      return {
        ...state,
        turnaroundPatchSaving: action.value,
      };
    case "setTurnaroundDetailsForConfirm":
      return {
        ...state,
        turnaroundDetailsForConfirm: action.value,
      };
    case "setTurnaroundDetailsForConfirmLoading":
      return {
        ...state,
        turnaroundDetailsForConfirmLoading: action.value,
      };
    case "setPinMarker":
      return {
        ...state,
        pinMarker: action.value,
      };
    case "setCrewShifts":
      return {
        ...state,
        crewShifts: action.value,
      };
    case "setCrewShiftsSaving":
      return {
        ...state,
        crewShiftsSaving: action.value,
      };
    case "setOperationTypes":
      return {
        ...state,
        operationTypes: action.value,
      };
    case "setOperationTypesLoading":
      return {
        ...state,
        operationTypesLoading: action.value,
      };
    case "setPerformanceOperations":
      return {
        ...state,
        performanceOperations: action.value,
      };
    case "setPerformanceOperationsLoading":
      return {
        ...state,
        performanceOperationsLoading: action.value,
      };
    case "setPerformanceCrew":
      return {
        ...state,
        performanceCrew: action.value,
      };
    case "setPerformanceCrewLoading":
      return {
        ...state,
        performanceCrewLoading: action.value,
      };
    case "setDepartments":
      return {
        ...state,
        departments: action.value,
      };
    case "setDepartmentsLoading":
      return {
        ...state,
        departmentsLoading: action.value,
      };
    case "setAircrafts":
      return {
        ...state,
        aircrafts: action.value,
      };
    case "setAircraftsLoading":
      return {
        ...state,
        aircraftsLoading: action.value,
      };
    case "setUnreadChatChannels":
      return {
        ...state,
        unreadChatChannels: action.value,
      };
    case "setUnreadTurnaroundChatChannels":
      return {
        ...state,
        unreadTurnaroundChatChannels: action.value,
      };
    case "setItemsToWhitelist":
      // proxy for just updating the whitelist in mapFilters
      const mapFiltersUpdated = deepCopy(state.mapFilters);
      mapFiltersUpdated.whitelist = action.value;
      return {
        ...state,
        mapFilters: mapFiltersUpdated,
      };
    case "setMapFilters":
      return {
        ...state,
        mapFilters: action.value,
      };
    case "setCrewShiftsExport":
      return {
        ...state,
        crewShiftsExport: action.value,
      };
    case "setCrewShiftsExportSaving":
      return {
        ...state,
        crewShiftsExportSaving: action.value,
      };
    case "setTurnaroundsSummaryExport":
      return {
        ...state,
        turnaroundsSummaryExport: action.value,
      };
    case "setTurnaroundsSummaryExportSaving":
      return {
        ...state,
        turnaroundsSummaryExportSaving: action.value,
      };
    case "setLocalSettings":
      return {
        ...state,
        localSettings: action.value,
      };
    case "setTurnaroundForAssignment":
      return {
        ...state,
        turnaroundForAssignment: action.value,
      };
    case "setAirlines":
      return {
        ...state,
        airlines: action.value,
      };
    case "setAirlinesLoading":
      return {
        ...state,
        airlinesLoading: action.value,
      };
    case "setAircraftsForAirline":
      return {
        ...state,
        aircraftsForAirline: action.value,
      };
    case "setAircraftsForAirlineLoading":
      return {
        ...state,
        aircraftsForAirlineLoading: action.value,
      };
    case "setAirportsForAirline":
      return {
        ...state,
        airportsForAirline: action.value,
      };
    case "setAirportsForAirlineLoading":
      return {
        ...state,
        airportsForAirlineLoading: action.value,
      };
    case "setFlightsCreate":
      return {
        ...state,
        flightsCreate: action.value,
      };
    case "setFlightsCreateSaving":
      return {
        ...state,
        flightsCreateSaving: action.value,
      };
    default:
      console.log(`Unknown action: ${action.type}`);
      //throw Error(`Unknown action: ${action.type}`);
      return {
        ...state,
      };
  }
}

export default function MainProvider({ children }) {
  const [main, dispatch] = useReducer(mainReducer, initialState);
  return (
    <MainContext.Provider value={main}>
      <MainDispatchContext.Provider value={dispatch}>
        {children}
      </MainDispatchContext.Provider>
    </MainContext.Provider>
  );
}
