import { useEffect, useState, useRef, useMemo } from "react";
import {
  filterBySearchQuery,
  getByFieldValue,
  isBlank,
  isNullOrUndefined,
  listWithMaxLength,
  sortByField,
} from "../../utils";
import CrewInfo from "../CrewInfo";
import { ReactComponent as Search } from "../../assets/search.svg";
import { ReactComponent as Cross } from "../../assets/circle-cross-dark.svg";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../userUtils";
import { getCrewAvailability } from "../../crewShiftUtils";
import { getTurnaroundInfoForTurnaroundSummary } from "../../turnaroundUtils";

const MAX_RESULTS = 6;

function SelectCrewMember(props) {
  const {
    onSelect,
    onCancel,
    certification,
    crewAssignments,
    users,
    positions,
    turnarounds,
    isAssignMode,
    disabled,
    shiftContext,
  } = props;
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsUnavailable, setSearchResultsUnavailable] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const [availableCrew, unavailableCrew] = useMemo(() => {
    const userInfoList = [];
    const availableCrewList = [];
    const unavailableCrewList = [];
    if (!isNullOrUndefined(users)) {
      const excludedUserUuids = crewAssignments?.map((item) => item.userUuid);
      // Filter out excludedUsers
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        if (!excludedUserUuids?.some((item) => item === user.uuid)) {
          const userInfo = getUserInfo(user, turnarounds, positions);
          userInfoList.push(userInfo);
        }
      }
    }

    // Include only certified crew members
    for (let i = 0; i < userInfoList.length; i++) {
      const userInfo = userInfoList[i];
      const user = userInfo.user;
      if (
        isNullOrUndefined(certification) ||
        user.certifications.some((item) => item.uuid === certification.uuid)
      ) {
        // Certifications OK, bucket by available
        const crewAvailability = !isNullOrUndefined(shiftContext)
          ? getCrewAvailability(userInfo, shiftContext)
          : null;
        const isOnShift = !isNullOrUndefined(crewAvailability?.userShift);
        const hasAssignedTask = !isNullOrUndefined(
          crewAvailability?.assignedTask
        );
        if (isOnShift && !hasAssignedTask) {
          availableCrewList.push({
            ...userInfo,
            crewAvailability: crewAvailability,
          });
        } else {
          unavailableCrewList.push({
            ...userInfo,
            crewAvailability: crewAvailability,
          });
        }
      }
    }
    sortByField(availableCrewList, "fullName");
    sortByField(unavailableCrewList, "fullName");
    return [availableCrewList, unavailableCrewList];
  }, [
    users,
    positions,
    turnarounds,
    certification,
    crewAssignments,
    shiftContext,
  ]);

  const handleSearchUsers = (value) => {
    setSearchTerm(value);

    const results = filterBySearchQuery(
      value,
      availableCrew,
      "fullName"
      // MAX_RESULTS
    );
    setSearchResults(results);

    const resultsUnavailable = filterBySearchQuery(
      value,
      unavailableCrew,
      "fullName"
      // MAX_RESULTS
    );
    setSearchResultsUnavailable(resultsUnavailable);
  };

  const showSuggested = isBlank(searchTerm);
  const availableList = showSuggested
    ? listWithMaxLength(availableCrew, MAX_RESULTS)
    : searchResults;
  const unavailableList = showSuggested
    ? listWithMaxLength(unavailableCrew, MAX_RESULTS)
    : searchResultsUnavailable;
  const availableSectionTitle = showSuggested
    ? t("recommended_certified_crew")
    : t("certified_available_crew");

  const buttonLabel = isAssignMode ? t("assign") : t("add_non_cap");

  const hasSpecificCertification = !isNullOrUndefined(certification);

  return (
    <div className="select-crew">
      <div className="select-crew-search">
        <div className="search-input">
          <div className="select-crew-container">
            <div>
              <Search />
            </div>
            <div>
              <input
                ref={inputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  handleSearchUsers(e.target.value);
                }}
                placeholder={t("search_crew_member")}
                onKeyDown={(e) => {
                  // Select the single result
                  const listLength =
                    (availableList?.length || 0) +
                    (unavailableList?.length || 0);
                  if (e.key === "Enter" && listLength === 1) {
                    if (availableList?.length === 1) {
                      onSelect(availableList[0]);
                    } else if (unavailableList?.length === 1) {
                      onSelect(unavailableList[0]);
                    }
                    e.stopPropagation();
                  }
                }}
              />
            </div>
            <div>
              <Cross
                onClick={() => {
                  handleSearchUsers("");
                  inputRef.current.focus();
                  if (!isNullOrUndefined(onCancel)) {
                    onCancel();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="select-crew-results-container">
        <div className="select-crew-results">
          {availableList?.length === 0 && unavailableList?.length === 0 && (
            <div>
              <div className="de-emphasize">No results</div>
            </div>
          )}
          {availableList?.length > 0 && (
            <>
              {hasSpecificCertification && (
                <div>
                  <div className="section-label">{availableSectionTitle}</div>
                </div>
              )}
              {availableList &&
                availableList.map((info) => (
                  <div
                    className="select-crew-result-row"
                    key={info.uuid}
                    onClick={(e) => {
                      onSelect(info);
                      e.stopPropagation();
                    }}
                  >
                    <div>
                      <CrewInfo
                        user={info.user}
                        details={
                          <CrewAvailabilityDetails
                            crewAvailability={info.crewAvailability}
                            turnarounds={turnarounds}
                          />
                        }
                        mapStatus={info.mapStatus}
                      />
                      <div>
                        <button
                          className="semiblue"
                          onClick={(e) => {
                            onSelect(info);
                            inputRef.current.focus();
                            e.stopPropagation();
                          }}
                          disabled={disabled}
                        >
                          {buttonLabel}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
          {unavailableList?.length > 0 && (
            <>
              {hasSpecificCertification && (
                <div>
                  <div className="section-label">
                    {t("certified_unavailable_crew")}
                  </div>
                </div>
              )}
              {unavailableList &&
                unavailableList.map((info) => {
                  return (
                    <div
                      className="select-crew-result-row not-available"
                      key={info.uuid}
                      onClick={(e) => {
                        const user = info;
                        onSelect(user);
                        e.stopPropagation();
                      }}
                    >
                      <div>
                        <CrewInfo
                          user={info.user}
                          details={
                            <CrewAvailabilityDetails
                              crewAvailability={info.crewAvailability}
                              turnarounds={turnarounds}
                            />
                          }
                          mapStatus={info.mapStatus}
                        />
                        <div>
                          <button
                            className="semiblue"
                            onClick={(e) => {
                              onSelect(info);
                              inputRef.current.focus();
                              e.stopPropagation();
                            }}
                            disabled={disabled}
                          >
                            {buttonLabel}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectCrewMember;

function CrewAvailabilityDetails(props) {
  const { t } = useTranslation();
  const { crewAvailability, turnarounds } = props;
  // User either has a assignedTask or userShift
  const isOnShift = !isNullOrUndefined(crewAvailability?.userShift);
  const turnaroundForTask = !isNullOrUndefined(
    crewAvailability?.assignedTask?.turnaroundUuid
  )
    ? getByFieldValue(
        turnarounds,
        "uuid",
        crewAvailability?.assignedTask?.turnaroundUuid
      )
    : null;
  const turnaroundInfoForTask = !isNullOrUndefined(turnaroundForTask)
    ? getTurnaroundInfoForTurnaroundSummary(turnaroundForTask)
    : null;

  return (
    <div>
      {isOnShift && isNullOrUndefined(turnaroundInfoForTask) && t("on_shift")}
      {!isOnShift && t("unavailable_not_online_during_turnaround")}

      {!isNullOrUndefined(turnaroundInfoForTask) && (
        <>
          {`${t("unavailable_assigned")}: ${
            turnaroundInfoForTask.combinedFlightName
          }`}
        </>
      )}
    </div>
  );
}
