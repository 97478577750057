import { ReactComponent as Chat } from "../../assets/chat.svg";
import { useMainContext } from "../../MainContext";
import { getByFieldValue, isEmptyList } from "../../utils";

function ChatButton(props) {
  const { turnaroundUuid } = props;
  const mainContext = useMainContext();
  const { turnaroundsSummary } = mainContext;
  const turnaroundSummary = !isEmptyList(turnaroundsSummary)
    ? getByFieldValue(turnaroundsSummary, "uuid", turnaroundUuid)
    : null;
  const chatCount = turnaroundSummary?.numUnreadMessages;
  return (
    <div className={`chat-button${chatCount > 0 ? " active" : ""}`}>
      <div>
        {chatCount > 0 && (
          <div className="chat-button-indicator">
            <div>{chatCount > 9 ? "9+" : chatCount}</div>
          </div>
        )}
        <div>
          <Chat />
        </div>
      </div>
    </div>
  );
}

export default ChatButton;
