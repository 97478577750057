import {
  formatHoursMins,
  formatTime,
  isBlank,
  isNullOrUndefined,
  zeroPad,
} from "../../utils";
import {
  resolveTurnaroundStands,
  TurnaroundStatus,
} from "../../turnaroundUtils";
import TurnaroundLeads from "../TurnaroundsModule/TurnaroundLeads";
import TurnaroundRemarks from "../TurnaroundsModule/TurnaroundRemarks";

import { ColumnActionType } from ".";
import { EMPTY_VALUE, FliteTimeTypes } from "../../constants";
import TurnaroundName from "../TurnaroundsModule/TurnaroundName";

export const TurnaroundsModuleColumns = {
  TurnaroundName: {
    id: "turnaround_name",
    displayName: "turnaround",
    formatter: (item) => {
      return (
        <div>
          <TurnaroundName item={item} />
        </div>
      );
    },
    sortValue: (item) => `${item.combinedFlightName}`,
  },
  Registration: {
    id: "registration",
    displayName: "registration",
    formatter: (item) => {
      return item?.registration;
    },
    sortValue: (item) => `${item.registration}`,
  },
  Landing: {
    id: "landing",
    displayName: "landing_non_cap",
    formatter: (item) => {
      return formatTime(item?.landing, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.landing) ? new Date(item?.landing) : null,
  },
  ScheduledIn: {
    id: "scheduled_in",
    displayName: "scheduled_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedInboundTimes?.displayTimeType ===
          FliteTimeTypes.SCHEDULED;
      const timeVal = !isNullOrUndefined(
        item?.inboundFlight?.timestamps?.scheduledGateIn
      )
        ? formatTime(
            item?.inboundFlight?.timestamps?.scheduledGateIn,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      return (
        <div
          className={`turnaround-time-values${
            isInvalidTime ? " invalid-time" : ""
          }`}
        >
          {timeVal}
        </div>
      );
    },
    sortValue: (item) => {
      // Status
      let statusCode = "";
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        statusCode = 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        statusCode = 3;
      } else {
        // All other status code sort after in-progress
        statusCode = 1;
      }

      // Time in
      const dateVal = !isNullOrUndefined(
        item?.inboundFlight?.timestamps?.scheduledGateIn
      )
        ? new Date(item?.inboundFlight?.timestamps?.scheduledGateIn).getTime()
        : 0;
      const dateValPadded = zeroPad(dateVal);
      return `${statusCode}_${dateValPadded}`;
    },
  },
  EstimatedIn: {
    id: "estimated_in",
    displayName: "estimated_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedInboundTimes?.displayTimeType ===
          FliteTimeTypes.ESTIMATED;
      const hasValue = !isNullOrUndefined(
        item?.inboundFlight?.timestamps?.estimatedGateIn
      );
      const timeVal = hasValue
        ? formatTime(
            item?.inboundFlight?.timestamps?.estimatedGateIn,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      return (
        <div
          className={`turnaround-time-values${
            hasValue ? " highlight-value" : ""
          }${isInvalidTime ? " invalid-time" : ""}`}
        >
          {timeVal}
        </div>
      );
    },
    sortValue: (item) => {
      // Status
      let statusCode = "";
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        statusCode = 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        statusCode = 3;
      } else {
        // All other status code sort after in-progress
        statusCode = 1;
      }

      // Time in
      const dateVal = !isNullOrUndefined(
        item?.inboundFlight?.timestamps?.estimatedGateIn
      )
        ? new Date(item?.inboundFlight?.timestamps?.estimatedGateIn).getTime()
        : 0;
      const dateValPadded = zeroPad(dateVal);
      return `${statusCode}_${dateValPadded}`;
    },
  },
  ActualIn: {
    id: "actual_in",
    displayName: "actual_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedInboundTimes?.displayTimeType === FliteTimeTypes.ACTUAL;
      const hasValue = !isNullOrUndefined(
        item?.inboundFlight?.timestamps?.actualGateIn
      );
      const timeVal = hasValue
        ? formatTime(
            item?.inboundFlight?.timestamps?.actualGateIn,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      return (
        <div
          className={`turnaround-time-values${
            hasValue ? " highlight-value" : ""
          }${isInvalidTime ? " invalid-time" : ""}`}
        >
          {timeVal}
        </div>
      );
    },
    sortValue: (item) => {
      // Status
      let statusCode = "";
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        statusCode = 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        statusCode = 3;
      } else {
        // All other status code sort after in-progress
        statusCode = 1;
      }

      // Time in
      const dateVal = !isNullOrUndefined(
        item?.inboundFlight?.timestamps?.actualGateIn
      )
        ? new Date(item?.inboundFlight?.timestamps?.actualGateIn).getTime()
        : 0;
      const dateValPadded = zeroPad(dateVal);
      return `${statusCode}_${dateValPadded}`;
    },
  },
  ScheduledOut: {
    id: "scheduled_out",
    displayName: "scheduled_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedOutboundTimes?.displayTimeType ===
          FliteTimeTypes.SCHEDULED;
      const timeVal = !isNullOrUndefined(
        item?.outboundFlight?.timestamps?.scheduledGateOut
      )
        ? formatTime(
            item?.outboundFlight?.timestamps?.scheduledGateOut,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      return (
        <div
          className={`turnaround-time-values${
            isInvalidTime ? " invalid-time" : ""
          }`}
        >
          {timeVal}
        </div>
      );
    },
    sortValue: (item) => {
      // Status
      let statusCode = "";
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        statusCode = 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        statusCode = 3;
      } else {
        // All other status code sort after in-progress
        statusCode = 1;
      }

      // Time out
      const dateVal = !isNullOrUndefined(
        item?.outboundFlight?.timestamps?.scheduledGateOut
      )
        ? new Date(item?.outboundFlight?.timestamps?.scheduledGateOut).getTime()
        : 0;
      const dateValPadded = zeroPad(dateVal);
      return `${statusCode}_${dateValPadded}`;
    },
  },
  EstimatedOut: {
    id: "estimated_out",
    displayName: "estimated_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedOutboundTimes?.displayTimeType ===
          FliteTimeTypes.ESTIMATED;
      const hasValue = !isNullOrUndefined(
        item?.outboundFlight?.timestamps?.estimatedGateOut
      );
      const timeVal = hasValue
        ? formatTime(
            item?.outboundFlight?.timestamps?.estimatedGateOut,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      return (
        <div
          className={`turnaround-time-values${
            hasValue ? " highlight-value" : ""
          }${isInvalidTime ? " invalid-time" : ""}`}
        >
          {timeVal}
        </div>
      );
    },
    sortValue: (item) => {
      // Status
      let statusCode = "";
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        statusCode = 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        statusCode = 3;
      } else {
        // All other status code sort after in-progress
        statusCode = 1;
      }

      // Time out
      const dateVal = !isNullOrUndefined(
        item?.outboundFlight?.timestamps?.estimatedGateOut
      )
        ? new Date(item?.outboundFlight?.timestamps?.estimatedGateOut).getTime()
        : 0;
      const dateValPadded = zeroPad(dateVal);
      return `${statusCode}_${dateValPadded}`;
    },
  },
  ActualOut: {
    id: "actual_out",
    displayName: "actual_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedOutboundTimes?.displayTimeType === FliteTimeTypes.ACTUAL;
      const hasValue = !isNullOrUndefined(
        item?.outboundFlight?.timestamps?.actualGateOut
      );
      const timeVal = hasValue
        ? formatTime(
            item?.outboundFlight?.timestamps?.actualGateOut,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      return (
        <div
          className={`turnaround-time-values${
            hasValue ? " highlight-value" : ""
          }${isInvalidTime ? " invalid-time" : ""}`}
        >
          {timeVal}
        </div>
      );
    },
    sortValue: (item) => {
      // Status
      let statusCode = "";
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        statusCode = 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        statusCode = 3;
      } else {
        // All other status code sort after in-progress
        statusCode = 1;
      }

      // Time out
      const dateVal = !isNullOrUndefined(
        item?.outboundFlight?.timestamps?.actualGateOut
      )
        ? new Date(item?.outboundFlight?.timestamps?.actualGateOut).getTime()
        : 0;
      const dateValPadded = zeroPad(dateVal);
      return `${statusCode}_${dateValPadded}`;
    },
  },
  Takeoff: {
    id: "takeoff",
    displayName: "takeoff_non_cap",
    formatter: (item) => {
      return formatTime(item?.takeoff, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.takeoff) ? new Date(item?.takeoff) : null,
  },
  TotalGate: {
    id: "total_gate_time",
    displayName: "total_gate_time",
    formatter: (item) => {
      return formatHoursMins(item?.gateTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateTimeMinutes) ? item?.gateTimeMinutes : 0,
  },
  TotalGround: {
    id: "total_ground_time",
    displayName: "total_ground_time",
    formatter: (item) => {
      return formatHoursMins(item?.groundTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.groundTimeMinutes) ? item?.groundTimeMinutes : 0,
  },
  Status: {
    id: "status",
    displayName: "status",
    formatter: (item) => {
      let statusClass = `status-${item.statusCode}`;
      const statusName = item?.statusDisplayName;
      return (
        <div
          className={`datatable-turnaround-status ${statusClass}`}
          data-status={statusName}
        >
          {statusName}
        </div>
      );
    },
    sortValue: (item) => {
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        return 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        return 3;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundPlanned) {
        return 1;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundNotConfigured) {
        return 2;
      }
    },
  },
  Leads: {
    id: "lead",
    displayName: "lead",
    formatter: (item, actionHandler) => {
      return (
        <div>
          <TurnaroundLeads
            item={item}
            onClick={() => {
              actionHandler(ColumnActionType.DISPATCH_EVENT, null, null, {
                type: "setTurnaroundForAssignment",
                value: item,
              });
            }}
          />
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
  Stand: {
    id: "stand",
    displayName: "stand_non_cap",
    formatter: (item) => {
      const turnaroundStands = resolveTurnaroundStands(item);
      const hasPrevStand = !isBlank(turnaroundStands.prevStand);

      return (
        <div className="turnaround-stand">
          <div className={hasPrevStand ? "highlight-value" : ""}>
            {turnaroundStands.currentStand}
          </div>
          {hasPrevStand && (
            <div className="previous-value">{turnaroundStands.prevStand}</div>
          )}
        </div>
      );
    },
    sortValue: (item) => {
      const turnaroundStands = resolveTurnaroundStands(item);
      return turnaroundStands.currentStand;
    },
  },
  Remarks: {
    id: "remarks",
    displayName: "remarks",
    formatter: (item) => {
      return (
        <div>
          <TurnaroundRemarks
            item={item}
            isReadonly={true}
            onClick={(e) => {
              // e.preventDefault();
              // e.stopPropagation();
            }}
          />
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
  OriginDestination: {
    id: "origin_destination",
    displayName: "origin_destination",
    formatter: (item) => {
      const origin = !isBlank(item?.inboundFlight?.originAirportIata)
        ? item?.inboundFlight?.originAirportIata
        : null;
      const destination = !isBlank(item?.outboundFlight?.destAirportIata)
        ? item?.outboundFlight?.destAirportIata
        : null;
      return (
        <div className="origin-destination">
          <div>
            {origin}
            {!isBlank(origin) && !isBlank(destination) && <>&nbsp;-&nbsp;</>}
          </div>
          <div>{destination}</div>
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
};
