import { formatDate, formatTime, isNullOrUndefined } from "../../utils";

export const FlightSummaryColumns = {
  FlightScheduledIn: {
    id: "flight_scheduled_in",
    displayName: "scheduled_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      return (
        <div className="flight-summary-time-value">
          <div>
            {!isNullOrUndefined(item.scheduledIn) &&
              formatTime(item.scheduledIn, item.airportTimezone)}
          </div>
          <div>
            {!isNullOrUndefined(item.scheduledIn) && (
              <span>{formatDate(item.scheduledIn, item.airportTimezone)}</span>
            )}
          </div>
        </div>
      );
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.scheduledIn)
        ? new Date(item?.scheduledIn)
        : null,
  },
  FlightScheduledOut: {
    id: "flight_scheduled_out",
    displayName: "scheduled_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      return (
        <div className="flight-summary-time-value">
          <div>
            {!isNullOrUndefined(item.scheduledOut) &&
              formatTime(item.scheduledOut, item.airportTimezone)}
          </div>
          <div>
            {!isNullOrUndefined(item.scheduledOut) && (
              <span>{formatDate(item.scheduledOut, item.airportTimezone)}</span>
            )}
          </div>
        </div>
      );
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.scheduledOut)
        ? new Date(item?.scheduledOut)
        : null,
  },

  FlightEstimatedIn: {
    id: "flight_estimated_in",
    displayName: "estimated_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      return (
        <div className="flight-summary-time-value">
          <div>
            {!isNullOrUndefined(item.estimatedIn) &&
              formatTime(item.estimatedIn, item.airportTimezone)}
          </div>
          <div>
            {!isNullOrUndefined(item.estimatedIn) && (
              <span>{formatDate(item.estimatedIn, item.airportTimezone)}</span>
            )}
          </div>
        </div>
      );
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.estimatedIn)
        ? new Date(item?.estimatedIn)
        : null,
  },
  FlightEstimatedOut: {
    id: "flight_estimated_out",
    displayName: "estimated_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      return (
        <div className="flight-summary-time-value">
          <div>
            {!isNullOrUndefined(item.estimatedOut) &&
              formatTime(item.estimatedOut, item.airportTimezone)}
          </div>
          <div>
            {!isNullOrUndefined(item.estimatedOut) && (
              <span>{formatDate(item.estimatedOut, item.airportTimezone)}</span>
            )}
          </div>
        </div>
      );
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.estimatedOut)
        ? new Date(item?.estimatedOut)
        : null,
  },

  FlightActualIn: {
    id: "flight_actual_in",
    displayName: "actual_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      return (
        <div className="flight-summary-time-value">
          <div>
            {!isNullOrUndefined(item.actualIn) &&
              formatTime(item.actualIn, item.airportTimezone)}
          </div>
          <div>
            {!isNullOrUndefined(item.actualIn) && (
              <span>{formatDate(item.actualIn, item.airportTimezone)}</span>
            )}
          </div>
        </div>
      );
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.actualIn) ? new Date(item?.actualIn) : null,
  },
  FlightActualOut: {
    id: "flight_actual_out",
    displayName: "actual_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      return (
        <div className="flight-summary-time-value">
          <div>
            {!isNullOrUndefined(item.actualOut) &&
              formatTime(item.actualOut, item.airportTimezone)}
          </div>
          <div>
            {!isNullOrUndefined(item.actualOut) && (
              <span>{formatDate(item.actualOut, item.airportTimezone)}</span>
            )}
          </div>
        </div>
      );
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.actualOut) ? new Date(item?.actualOut) : null,
  },
};
