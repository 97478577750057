import moment from "moment-timezone";
import { deepCopy, isNullOrUndefined } from "../../utils";
import TimeInput from "../TimeRangeInput/TimeInput";
import ButtonDatePicker from "../ButtonDatePicker";
import { MIN_DATE } from "../../constants";
import { ReactComponent as CircleCross } from "../../assets/circle_cross.svg";
import { useTranslation } from "react-i18next";

function CreateFlightTimeConfig(props) {
  const { onChange, flightForCreate, timeFieldName, timezone, label } = props;
  const { t } = useTranslation();
  const mToday = moment().tz(timezone).startOf("day");

  const value = flightForCreate[timeFieldName];

  return (
    <div className="time-configuration allow-clear">
      <div>
        <label>{label}</label>
      </div>
      <div className="time-configuration-time-value">
        {!isNullOrUndefined(value) && (
          <div className="time-input-container">
            <div>
              <TimeInput
                timeValue={value}
                timezone={timezone}
                onTimeChange={(dateStrVal) => {
                  const flightToUpdate = deepCopy(flightForCreate);
                  const newVal = moment(dateStrVal).tz(timezone);
                  const updatedVal = moment(flightToUpdate[timeFieldName]).tz(
                    timezone
                  );
                  updatedVal.set("hours", newVal.hours());
                  updatedVal.set("minutes", newVal.minutes());
                  flightToUpdate[timeFieldName] = updatedVal.toISOString();
                  onChange(flightToUpdate);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="time-configuration-date-value">
        {!isNullOrUndefined(value) && (
          <ButtonDatePicker
            label={moment(value).tz(timezone).format("MM/DD/YYYY")}
            value={moment(value).tz(timezone)}
            onChange={(newValue) => {
              // update
              const flightToUpdate = deepCopy(flightForCreate);
              const updatedVal = moment(value).tz(timezone);
              updatedVal.set("date", newValue.date());
              updatedVal.set("month", newValue.month());
              updatedVal.set("year", newValue.year());
              flightToUpdate[timeFieldName] = updatedVal.toISOString();
              onChange(flightToUpdate);
            }}
            minDate={moment(MIN_DATE)}
            maxDate={moment(mToday).add(2, "month")}
          />
        )}
      </div>
      <div>
        {!isNullOrUndefined(value) && (
          <div
            className="clear-input"
            onClick={() => {
              const flightToUpdate = deepCopy(flightForCreate);
              flightToUpdate[timeFieldName] = null;
              onChange(flightToUpdate);
            }}
          >
            <CircleCross />
          </div>
        )}
        {isNullOrUndefined(value) && (
          <div>
            <button
              className="secondary"
              onClick={() => {
                // update
                const flightToUpdate = deepCopy(flightForCreate);
                flightToUpdate[timeFieldName] = mToday.toISOString();
                onChange(flightToUpdate);
              }}
            >
              {t("select_date_time")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateFlightTimeConfig;
