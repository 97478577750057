import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "../../utils";
import { EMPTY_VALUE } from "../../constants";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import FlightTimeValue from "../FlightValues/FlightTimeValue";

function TurnaroundModuleInfo(props) {
  const { t } = useTranslation();
  const { selectedTurnaroundInfo, timezone } = props;

  // Display each specific type, in other words don't resolve
  const inboundFlight = selectedTurnaroundInfo?.inboundFlight;
  const outboundFlight = selectedTurnaroundInfo?.outboundFlight;
  const scheduledIn = inboundFlight?.timestamps?.scheduledGateIn;
  const scheduledOut = outboundFlight?.timestamps?.scheduledGateOut;
  const estimatedIn = inboundFlight?.timestamps?.estimatedGateIn;
  const estimatedOut = outboundFlight?.timestamps?.estimatedGateOut;
  const actualIn = inboundFlight?.timestamps?.actualGateIn;
  const actualOut = outboundFlight?.timestamps?.actualGateOut;

  const assignedStand = selectedTurnaroundInfo?.assignedStand;
  const snappedStand = selectedTurnaroundInfo?.snappedStand;

  return (
    <div className="turnaround-module-info">
      <div className="turnaround-module-info-stats">
        <div className="turnaround-module-info-row">
          <div>
            <Arrival />
          </div>
          <div className="bigstat">
            <div>{selectedTurnaroundInfo.inboundFlightName}</div>
          </div>
          <InfoStat
            label={t("origin_non_cap")}
            value={selectedTurnaroundInfo.originAirport}
          />
          <InfoStat
            label={t("scheduled_time_arrival_abbr")}
            value={
              <FlightTimeValue
                resolvedFlightTimes={{
                  displayTime: scheduledIn,
                  hasTimeChanged: false,
                }}
                timezone={timezone}
                showDate={true}
              />
            }
          />
          <InfoStat
            label={t("estimated_time_arrival_abbr")}
            value={
              <FlightTimeValue
                resolvedFlightTimes={{
                  displayTime: estimatedIn,
                  hasTimeChanged: false,
                }}
                timezone={timezone}
                showDate={true}
              />
            }
          />
          <InfoStat
            label={t("actual_time_arrival_abbr")}
            value={
              <FlightTimeValue
                resolvedFlightTimes={{
                  displayTime: actualIn,
                  hasTimeChanged: false,
                }}
                timezone={timezone}
                showDate={true}
              />
            }
          />

          <InfoStat
            label={t("current_stand")}
            value={
              !isNullOrUndefined(snappedStand) ? snappedStand.name : EMPTY_VALUE
            }
          />
        </div>
        <div className="turnaround-module-info-row">
          <div>
            <Departure />
          </div>
          <div className="bigstat">
            <div>{selectedTurnaroundInfo.outboundFlightName}</div>
          </div>
          <InfoStat
            label={t("destination_non_cap")}
            value={selectedTurnaroundInfo.destinationAirport}
          />
          <InfoStat
            label={t("scheduled_time_departure_abbr")}
            value={
              <FlightTimeValue
                resolvedFlightTimes={{
                  displayTime: scheduledOut,
                  hasTimeChanged: false,
                }}
                timezone={timezone}
                showDate={true}
              />
            }
          />
          <InfoStat
            label={t("estimated_time_departure_abbr")}
            value={
              <FlightTimeValue
                resolvedFlightTimes={{
                  displayTime: estimatedOut,
                  hasTimeChanged: false,
                }}
                timezone={timezone}
                showDate={true}
              />
            }
          />
          <InfoStat
            label={t("actual_time_departure_abbr")}
            value={
              <FlightTimeValue
                resolvedFlightTimes={{
                  displayTime: actualOut,
                  hasTimeChanged: false,
                }}
                timezone={timezone}
                showDate={true}
              />
            }
          />
          <InfoStat
            label={t("assigned_stand")}
            value={
              !isNullOrUndefined(assignedStand)
                ? assignedStand.name
                : EMPTY_VALUE
            }
          />
        </div>
      </div>
      <div className="divider">
        <div></div>
      </div>
      <div className="side-info">
        <div>
          <InfoStat
            label={t("remarks")}
            value={selectedTurnaroundInfo.remarks}
            isTextContent={true}
          />
        </div>
        <div>
          <InfoStat
            label={t("registration")}
            value={selectedTurnaroundInfo.registration}
          />
        </div>
      </div>
    </div>
  );
}

function InfoStat(props) {
  return (
    <div className={`stat${props.isTextContent ? " text-content" : ""}`}>
      <div>
        <div>
          <span>{props.label}</span>
        </div>
        <div>
          <span>{props.value}</span>
        </div>
      </div>
    </div>
  );
}
export default TurnaroundModuleInfo;
