import { useTranslation } from "react-i18next";
import { ReactComponent as CircleCheck } from "../../assets/check-green-circle.svg";
import {
  isPasswordContainsNumber,
  isPasswordContainsSpecialChars,
  isPasswordContainsUppercase,
  isPasswordMinLength,
} from "../../utils";

function PasswordRequirements(props) {
  const { password } = props;
  const { t } = useTranslation();

  const isMinLength = isPasswordMinLength(password);
  const hasNumber = isPasswordContainsNumber(password);
  const hasUppercase = isPasswordContainsUppercase(password);
  const hasSpecialChars = isPasswordContainsSpecialChars(password);
  return (
    <div className="password-requirments">
      <div>
        <div
          className={`password-requirment${isMinLength ? " completed" : ""}`}
        >
          <div>
            <CircleCheck />
          </div>
          <div>{t("password_requirement_min_length")}</div>
        </div>
        <div className={`password-requirment${hasNumber ? " completed" : ""}`}>
          <div>
            <CircleCheck />
          </div>
          <div>{t("password_requirement_number")}</div>
        </div>
        <div
          className={`password-requirment${hasUppercase ? " completed" : ""}`}
        >
          <div>
            <CircleCheck />
          </div>
          <div>{t("password_requirement_uppercase")}</div>
        </div>
        <div
          className={`password-requirment${
            hasSpecialChars ? " completed" : ""
          }`}
        >
          <div>
            <CircleCheck />
          </div>
          <div>{t("password_requirement_special_chars")}</div>
        </div>
      </div>
    </div>
  );
}

export default PasswordRequirements;
