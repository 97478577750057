import { useMainContext } from "../../MainContext";

function ChatIndicator(props) {
  const { mode } = props;
  const mainContext = useMainContext();
  const { unreadChatChannels, unreadTurnaroundChatChannels } = mainContext;

  const unreadCount =
    mode === "turnarounds" ? unreadTurnaroundChatChannels : unreadChatChannels;

  const showIndicator = unreadCount > 0;
  return (
    <>
      {showIndicator && (
        <div className="status-indicator">
          <div>{unreadCount}</div>
        </div>
      )}
    </>
  );
}

export default ChatIndicator;
